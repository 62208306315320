import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useMutation } from "@tanstack/react-query";
import React, { useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterShareButton,
  XIcon,
} from "react-share";

import { retrieveError } from "../../../utilities/constants";

import { yupResolver } from "@hookform/resolvers/yup";
import DeleteIcon from "@mui/icons-material/Delete";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import ShareIcon from "@mui/icons-material/Share";
import {
  Box,
  Button,
  Checkbox,
  Collapse,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import { Trans, useTranslation } from "react-i18next";
import * as yup from "yup";
import Notification from "../../../Notification";
import axiosInstance from "../../../useApi";

const FormAnswerPost = (props) => {
  const { idPost, answersCount, handleRefetchPosts } = props;
  const { t, i18n } = useTranslation();

  const [isDevisChecked, setIsDevisChecked] = useState(false);

  const [files, setFiles] = useState([]);

  const [open, setOpen] = React.useState(false);

  const schema = yup.object().shape({
    text_answer: yup.string().required(t("Text answer is required")),
    price: yup
      .number()
      .when("$isDevisChecked", (val, schema) =>
        !isDevisChecked ? schema.required(t("Price is required")) : schema
      ),
  });
  const [anchorEl, setAnchorEl] = useState(null);
  const openShareMenu = Boolean(anchorEl);

  const handleClickShare = (event) => {
    setAnchorEl(event?.currentTarget);
  };

  const handleCloseShare = () => {
    setAnchorEl(null);
  };
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const handleDeleteFile = (id) => {
    let updatedFL = files.filter((elt, index) => index !== id);
    setFiles(updatedFL);
  };

  const handleImageChange = (e) => {
    const selectedFiles = e.target.files;
    setFiles([...files, ...selectedFiles]);
  };

  const handleClick = () => {
    setOpen(!open);
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, dirtyFields },
    resetField,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const handleAnswerPost = () => {
    const formData = new FormData();
    formData.append("text", getValues("text_answer"));
    if (
      getValues("link") &&
      getValues("link").length > 0 &&
      getValues("link") !== undefined
    ) {
      formData.append("link", getValues("link"));
    }

    if (isDevisChecked) {
      formData.append("quotation", true);
    } else {
      formData.append("quotation", false);
      formData.append("price", getValues("price"));
    }

    const imageFiles = files.filter((elt) => elt.type.startsWith("image"));
    const nonImageFiles = files.filter((elt) => !elt.type.startsWith("image"));

    if (imageFiles.length > 0) {
      imageFiles.forEach((imageFile, index) => {
        formData.append(`images`, imageFile);
      });
    }

    if (nonImageFiles.length > 0) {
      nonImageFiles.forEach((nonImageFile, index) => {
        formData.append(`attached_files`, nonImageFile);
      });
    }

    formData.append("post", idPost);

    mutationAnswerPost.mutate(formData);
  };

  const handleDevisChecked = () => {
    setIsDevisChecked(!isDevisChecked);
  };

  const mutationAnswerPost = useMutation({
    mutationFn: (post) => {
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      return axiosInstance.post(
        "https://api-dev.seeks.biz/socialmedia/answers/",
        post,
        config
      );
    },
    onError: (error) => {
      setNotify({
        isOpen: true,
        message: retrieveError(error.response.data),
        type: "error",
      });
      console.log("Erreur lors de la soumission du formulaire : ", error);
    },
    onSuccess: (data, variables, context) => {
      setNotify({
        isOpen: true,
        message: t("Answer created successfully!"),
        type: "success",
      });
      setOpen(!open);
      handleRefetchPosts();
    },
  });

  return (
    <>
      <div className="comment-area mt-3">
        <div className="d-flex justify-content-between align-items-center flex-wrap">
          <div className="like-block position-relative d-flex align-items-center">
            <div className="d-flex align-items-center">
              <div className="total-like-block ms-2 me-3">
                {answersCount}{" "}
                {answersCount === 0 || answersCount === 1 ? (
                  <Trans>answer</Trans>
                ) : (
                  <Trans>answers</Trans>
                )}
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center feather-icon mt-2 mt-md-0">
            <Button onClick={handleClickShare}>
              <ShareIcon />
              <span className="ms-1">
                <Trans>Share</Trans>
              </span>
            </Button>
            <Menu
              anchorEl={anchorEl}
              open={openShareMenu}
              onClose={handleCloseShare}
            >
              <MenuItem onClick={handleCloseShare}>
                <FacebookShareButton
                  url={window.location.hostname + "/post/" + idPost}
                  className="Demo__some-network__share-button"
                >
                  <FacebookIcon size={32} round />
                </FacebookShareButton>
              </MenuItem>
              <MenuItem onClick={handleCloseShare}>
                <TwitterShareButton
                  url={window.location.hostname + "/post/" + idPost}
                  // title={""}
                  className="Demo__some-network__share-button"
                >
                  <XIcon size={32} round />
                </TwitterShareButton>
              </MenuItem>
              <MenuItem onClick={handleCloseShare}>
                <LinkedinShareButton
                  url={window.location.hostname + "/post/" + idPost}
                  className="Demo__some-network__share-button"
                >
                  <LinkedinIcon size={32} round />
                </LinkedinShareButton>
              </MenuItem>
            </Menu>
            <Button onClick={() => handleClick()}>
              <QuestionAnswerIcon />
              <span className="ms-1">
                <Trans>Answer</Trans>
              </span>
            </Button>
          </div>
        </div>
      </div>

      <Collapse in={open} timeout="auto" unmountOnExit>
        <form onSubmit={handleSubmit(handleAnswerPost)}>
          <hr />
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <div style={{ display: "flex" }}>
                <Controller
                  name="text_answer"
                  control={control}
                  key="text_answer"
                  render={({ field }) => (
                    <TextField
                      label={t("Text")}
                      variant="outlined"
                      fullWidth
                      margin="normal"
                      multiline
                      {...field}
                      // onChange={handleChangeEmail}
                    />
                  )}
                />
                <input
                  type="file"
                  className="custom-file-input"
                  id="logo"
                  accept="image/*, video/*, .pdf"
                  multiple
                  onChange={handleImageChange}
                  style={{ display: "none" }}
                />
                <label htmlFor="logo">
                  <AddPhotoAlternateIcon style={{ marginTop: "32px" }} />
                </label>
              </div>
              {errors.text_answer && (
                <span className="text-danger">
                  {errors.text_answer.message}
                </span>
              )}

              <div style={{ display: "flex" }}>
                <Grid container>
                  {files.map((file, index) =>
                    file.type.startsWith("image") ? (
                      <Grid item key={file}>
                        <Paper
                          elevation={3}
                          sx={{
                            position: "relative",
                            ":hover": {
                              "& .deleteButton": {
                                display: "block",
                              },
                            },
                          }}
                        >
                          <img
                            src={URL.createObjectURL(file)}
                            alt={`preview-${index}`}
                            className="avatar-60 img-fluid"
                          />
                          <IconButton
                            color="error"
                            onClick={() => handleDeleteFile(index)}
                            sx={{
                              position: "absolute",
                              top: "8px",
                              right: "8px",
                              display: "none",
                            }}
                            className="deleteButton"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Paper>
                      </Grid>
                    ) : null
                  )}
                </Grid>
              </div>

              <div style={{ display: "flex" }}>
                <Grid container spacing={2}>
                  {files.map((file, index) =>
                    file.type.startsWith("video") ? (
                      <Grid item key={file}>
                        <Box
                          elevation={3}
                          sx={{
                            position: "relative",
                            ":hover": {
                              "& .deleteButton": {
                                display: "block",
                              },
                            },
                          }}
                        >
                          <VideoLibraryIcon
                            color="error"
                            sx={{ fontSize: 64 }}
                          />
                          <Tooltip title={file.name}>
                            <Typography
                              variant="body1"
                              sx={{
                                maxWidth: 120,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {file.name}
                            </Typography>
                          </Tooltip>
                          <IconButton
                            color="error"
                            onClick={() => handleDeleteFile(index)}
                            sx={{
                              position: "absolute",
                              top: "8px",
                              right: "8px",
                              display: "none",
                            }}
                            className="deleteButton"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </Grid>
                    ) : null
                  )}
                </Grid>
              </div>

              <div style={{ display: "flex", marginTop: "12px" }}>
                <Grid container spacing={2}>
                  {files.map((file, index) =>
                    file.type.startsWith("application/pdf") ? (
                      <Grid item key={file}>
                        <Box
                          elevation={3}
                          sx={{
                            position: "relative",
                            ":hover": {
                              "& .deleteButton": {
                                display: "block",
                              },
                            },
                          }}
                        >
                          <PictureAsPdfIcon
                            color="error"
                            sx={{ fontSize: 64 }}
                          />
                          <Tooltip title={file.name}>
                            <Typography
                              variant="body1"
                              sx={{
                                maxWidth: 120,
                                overflow: "hidden",
                                textOverflow: "ellipsis",
                                whiteSpace: "nowrap",
                              }}
                            >
                              {file.name}
                            </Typography>
                          </Tooltip>
                          <IconButton
                            color="error"
                            onClick={() => handleDeleteFile(index)}
                            sx={{
                              position: "absolute",
                              top: "8px",
                              right: "8px",
                              display: "none",
                            }}
                            className="deleteButton"
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Box>
                      </Grid>
                    ) : null
                  )}
                </Grid>
              </div>
            </Grid>
            <Grid item md={6} xs={12} style={{ marginTop: "-24px" }}>
              <Controller
                name="price"
                control={control}
                key="price"
                render={({ field }) => (
                  <TextField
                    label={t("Price")}
                    variant="outlined"
                    fullWidth
                    margin="normal"
                    {...field}
                    disabled={isDevisChecked}
                    type="number"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">€</InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <div style={{ display: "grid" }}>
                {errors.price && (
                  <span className="text-danger">{errors.price.message}</span>
                )}
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={isDevisChecked}
                      onChange={handleDevisChecked}
                    />
                  }
                  label={t("Price on quote")}
                />
              </div>
            </Grid>
            <Grid item md={6} xs={12} style={{ marginTop: "-24px" }}>
              <Controller
                name="link"
                control={control}
                key="link"
                render={({ field }) => (
                  <TextField
                    label={t("Link (product or your company)")}
                    variant="outlined"
                    type="text"
                    fullWidth
                    margin="normal"
                    {...field}
                    // onChange={handleChangePassword}
                  />
                )}
              />
            </Grid>
          </Grid>
          <Button type="submit" variant="primary" className="float-end">
            <Trans>Send answer</Trans>
          </Button>
        </form>
      </Collapse>
      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

export default FormAnswerPost;
