import * as React from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Typography,
} from "@mui/material";
import { styled, useTheme } from "@mui/system";
import { Trans } from "react-i18next";

const StyledDialog = styled(Dialog)(({ theme }) => ({
  padding: theme.spacing(2),
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
}));

const StyledDialogTitle = styled(DialogTitle)({
  textAlign: "center",
});

const StyledDialogContent = styled(DialogContent)({
  textAlign: "center",
});

const StyledDialogActions = styled(DialogActions)({
  justifyContent: "center",
});

const TitleIcon = styled("div")(({ theme }) => ({
  backgroundColor: theme.palette.secondary.light,
  color: theme.palette.secondary.main,
  "&:hover": {
    backgroundColor: theme.palette.secondary.light,
    cursor: "default",
  },
  "& .MuiSvgIcon-root": {
    fontSize: "8rem",
  },
}));

const ConfirmDialog = (props) => {
  const { confirmDialog, setConfirmDialog } = props;
  const theme = useTheme();

  return (
    <StyledDialog open={confirmDialog.isOpen} hideBackdrop="true">
      <StyledDialogTitle></StyledDialogTitle>
      <StyledDialogContent>
        <Typography variant="h6">
          <Trans>{confirmDialog.title}</Trans>
        </Typography>
        <Typography variant="subtitle2">
          <Trans>{confirmDialog.subTitle}</Trans>
        </Typography>
      </StyledDialogContent>
      <StyledDialogActions>
        <Button
          onClick={() => setConfirmDialog({ ...confirmDialog, isOpen: false })}
        >
          <Trans>No</Trans>
        </Button>
        <Button onClick={confirmDialog.onConfirm}>
          <Trans>Yes</Trans>
        </Button>
      </StyledDialogActions>
    </StyledDialog>
  );
};

export default ConfirmDialog;
