import React, { useEffect, useState } from "react";
import { Row, Col, Container, Image, Button } from "react-bootstrap";

import { Trans, useTranslation } from "react-i18next";
//swiper
import SwiperCore, { Navigation, Autoplay } from "swiper";

// Import Swiper styles
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper-bundle.min.css";

import mail from "../../../assets/images/login/mail.png";
import Swipper from "./swipper";
SwiperCore.use([Navigation, Autoplay]);

const ConfirmAccountCreate = () => {
  return (
    <>
      <section className="sign-in-page">
        <div id="container-inside">
          <div id="circle-small"></div>
          <div id="circle-medium"></div>
          <div id="circle-large"></div>
          <div id="circle-xlarge"></div>
          <div id="circle-xxlarge"></div>
        </div>
        <Container className="p-0">
          <Row className="no-gutters">
            <Col md="6" className="text-center pt-5 order-md-1 order-2">
              <Swipper />
            </Col>
            <Col
              md="6"
              className="bg-white pt-5 pt-5 pb-lg-0 pb-5 order-md-2 order-1"
            >
              <div className="sign-in-from" style={{ marginTop: "242px" }}>
                <Image src={mail} width="80" alt="" />

                <p>
                  <Trans>
                    Your account has been successfully created. Please check
                    your e-mails to find the activation link.
                  </Trans>
                </p>
                <div className="d-inline-block w-100"></div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ConfirmAccountCreate;
