//router
// import IndexRouters from "./router/index"

//scss
import "./assets/scss/seeks.scss";
import "./assets/scss/customizer.scss";

import React, { useLayoutEffect } from "react";
import * as SettingSelector from "./store/setting/selectors";
import { ThemeProvider, createTheme } from "@mui/material/styles";
// Redux Selector / Action
import { useDispatch } from "react-redux";
import { Trans, useTranslation } from "react-i18next";
// import state selectors
import { setSetting } from "./store/setting/actions";
import { useSelector } from "react-redux";
const ColorModeContext = React.createContext({ toggleColorMode: () => {} });

function App(props) {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const themeScheme = useSelector(SettingSelector.theme_scheme);
  dispatch(setSetting());
  const [mode, setMode] = React.useState(
    themeScheme === "light" ? "dark" : "light"
  );
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
        localStorage.setItem("themeScheme", mode);
      },
    }),
    []
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
        },
      }),
    [mode]
  );

  useLayoutEffect(() => {
    colorMode.toggleColorMode();
    console.log(themeScheme);
  }, [themeScheme]);
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <div className="App">
          {/* <IndexRouters /> */}
          {props.children}
        </div>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
