import React, { useState, useContext, useEffect } from "react";
import { Row, Col, Container, Nav, Tab } from "react-bootstrap";
import { Avatar, IconButton, Tooltip } from "@mui/material";
import Card from "../../../components/Card";
import { Trans, useTranslation } from "react-i18next";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import loader from "../../../assets/images/page-img/page-load-loader.gif";
import ListMyPosts from "./list-my-posts";
import ListMyAnswers from "./list-my-answers";
import { getInitials, stringToColor } from "../../../utilities/constants";
import { JoyrideContext } from "../../../joyrideContext";

const UserProfile = () => {
  const { t, i18n } = useTranslation();
  const fullname = localStorage.getItem("fullname");
  const [postsCount, setPostsCount] = useState(0);
  const [answersCount, setAnswersCount] = useState(0);

  const {
    runValue,
    updateRunValue,
    stepIndexValue,
    updateStepIndexValue,
    tourActiveValue,
    updateTourActiveValue,
  } = useContext(JoyrideContext);

  const profilePictureUrl =
    localStorage.getItem("avatar") && localStorage.getItem("avatar") !== "null"
      ? localStorage.getItem("avatar")
      : "null";

  const handleScrollTop = () => {
    window.scrollTo(0, 0);
  };

  // useEffect(() => {
  //   if (tourActiveValue && localStorage.getItem("autoSkipJoyride") !== "true") {
  //     setTimeout(() => {
  //       updateRunValue(true);
  //       // if (stepIndexValue === 4) {
  //       //   updateStepIndexValue(5);
  //       // }
  //     }, 400);
  //     // return () => clearTimeout(timeoutId);
  //   }
  // }, []);

  return (
    <>
      <Container>
        <Row>
          <Col
            sm={12}
            style={{
              marginTop:
                profilePictureUrl && profilePictureUrl !== "null"
                  ? "124px"
                  : "24px",
            }}
          >
            <Card>
              <Card.Body
                className=" profile-page p-0"
                style={{ marginTop: "69px" }}
              >
                <div className="profile-header">
                  {/* <div className="position-relative">
                    <img
                      loading="lazy"
                      src={img1}
                      alt="profile-bg"
                      className="rounded img-fluid"
                    />
                    <ul className="header-nav list-inline d-flex flex-wrap justify-end p-0 m-0">
                      <li>
                        <Link to="#" className="material-symbols-outlined">
                          edit
                        </Link>
                      </li>
                      <li>
                        <Link to="#" className="material-symbols-outlined">
                          settings
                        </Link>
                      </li>
                    </ul>
                  </div> */}
                  <div className="user-detail text-center mb-3">
                    {profilePictureUrl && profilePictureUrl !== "null" ? (
                      <div className="profile-img">
                        <img
                          loading="lazy"
                          src={
                            profilePictureUrl && profilePictureUrl !== "null"
                              ? profilePictureUrl
                              : getInitials()
                          }
                          style={{ maxHeight: "130px", maxWidth: "130px" }}
                          alt="profile-picture"
                          className="avatar-130 img-fluid"
                        />
                      </div>
                    ) : null}
                    <div className="profile-detail">
                      <h3>{fullname}</h3>
                    </div>
                  </div>
                  <div className="profile-info p-3 d-flex align-items-center justify-content-between position-relative">
                    <div className="social-links">
                      <ul className="social-data-block  align-items-center justify-content-between list-inline p-0 m-0">
                        <li className="text-center ps-3">
                          <h6>
                            <Trans>Posts</Trans>
                          </h6>
                          <p className="mb-0">{postsCount}</p>
                        </li>
                      </ul>
                    </div>
                    <div className="social-info">
                      <ul className="social-data-block  align-items-center justify-content-between list-inline p-0 m-0">
                        <li className="text-center ps-3">
                          <h6>
                            <Trans>Answers</Trans>
                          </h6>
                          <p className="mb-0">{answersCount}</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12}>
            <Tab.Container id="left-tabs-example" defaultActiveKey="first">
              <Card>
                <Card.Body className="p-0">
                  <div className="user-tabing">
                    <Nav
                      as="ul"
                      variant="pills"
                      className="d-flex align-items-center justify-content-center profile-feed-items p-0 m-0"
                    >
                      <Nav.Item
                        as="li"
                        className=" col-12 col-sm-3 p-0 "
                        id="navItemMyPostsStep"
                      >
                        <Nav.Link
                          href="#pills-timeline-tab"
                          eventKey="first"
                          role="button"
                          className=" text-center p-3"
                        >
                          <Trans>My posts</Trans>
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item
                        as="li"
                        className="col-12 col-sm-3 p-0"
                        id="navItemMyAnswersStep"
                      >
                        <Nav.Link
                          href="#pills-about-tab"
                          eventKey="second"
                          role="button"
                          className="text-center p-3"
                        >
                          <Trans>My answers</Trans>
                        </Nav.Link>
                      </Nav.Item>
                    </Nav>
                  </div>
                </Card.Body>
              </Card>
              <Col sm={12}>
                <Tab.Content>
                  <Tab.Pane eventKey="first">
                    <Card.Body className=" p-0">
                      <Row>
                        <Col lg={12}>
                          <ListMyPosts
                            setPostsCount={setPostsCount}
                          ></ListMyPosts>
                        </Col>
                      </Row>
                    </Card.Body>
                  </Tab.Pane>
                  <Tab.Pane eventKey="second">
                    <Tab.Container
                      id="left-tabs-example"
                      defaultActiveKey="about1"
                    >
                      <Row>
                        <Col md={12}>
                          <ListMyAnswers
                            setAnswersCount={setAnswersCount}
                          ></ListMyAnswers>
                        </Col>
                      </Row>
                    </Tab.Container>
                  </Tab.Pane>

                  {/* <div className="col-sm-12 text-center">
                    <img
                      loading="lazy"
                      src={loader}
                      alt="loader"
                      style={{ height: "100px" }}
                    />
                  </div> */}
                </Tab.Content>
              </Col>
            </Tab.Container>
          </Col>
        </Row>
      </Container>
      <div style={{ position: "fixed", bottom: 16, right: 16 }}>
        <span>
          <Tooltip title={t("Scroll on top of the page")}>
            <IconButton
              onClick={() => {
                handleScrollTop();
              }}
            >
              <KeyboardArrowUpIcon />
            </IconButton>
          </Tooltip>
        </span>
      </div>
    </>
  );
};

export default UserProfile;
