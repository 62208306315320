import React, { createContext, useState } from "react";

const JoyrideContext = createContext();

const JoyrideProvider = ({ children }) => {
  const [runValue, setRunValue] = useState(
    localStorage.getItem("autoSkipJoyride") === "true" ? false : true
  );
  const [stepIndexValue, setStepIndexValue] = useState(0);
  const [tourActiveValue, setTourActiveValue] = useState(false);

  const updateStepIndexValue = (value) => {
    setStepIndexValue(value);
  };

  const updateRunValue = (value) => {
    setRunValue(value);
  };

  const updateTourActiveValue = (value) => {
    setTourActiveValue(value);
  };

  return (
    <JoyrideContext.Provider
      value={{
        runValue,
        updateStepIndexValue,
        stepIndexValue,
        updateRunValue,
        tourActiveValue,
        updateTourActiveValue,
      }}
    >
      {children}
    </JoyrideContext.Provider>
  );
};

export { JoyrideContext, JoyrideProvider };
