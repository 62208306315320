import { yupResolver } from "@hookform/resolvers/yup";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import SearchIcon from "@mui/icons-material/Search";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import {
  Autocomplete,
  Avatar,
  Button,
  Chip,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Tooltip,
} from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { format } from "date-fns";
import { enGB, fr } from "date-fns/locale";
import { useContext, useRef, useState, useEffect } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import * as yup from "yup";
import Notification from "../../../Notification";
import { HeaderContext } from "../../../components/partials/dashboard/HeaderStyle/headerContext";
import axiosInstance from "../../../useApi";
import {
  listThemes,
  retrieveError,
  stringToColor,
} from "../../../utilities/constants";
import ThemeScheme from "../../../components/setting/sections/theme-scheme";
import { useSelector } from "react-redux";
import * as SettingSelector from "../../../store/setting/selectors";

const UserAccountSetting = () => {
  const { t, i18n } = useTranslation();

  const themeScheme = useSelector(SettingSelector.theme_scheme);

  const { updateFullnameValue, updateAvatarValue } = useContext(HeaderContext);

  const schema = yup.object().shape({
    first_name: yup.string().required(t("First name is required")),
    last_name: yup.string().required(t("Last name is required")),
    email: yup
      .string()
      .email(t("Invalid email"))
      .required(t("Email is required")),
    password: yup.string(),
    company_name: yup.string().required(t("Company name is required")),
    siret: yup.string().required(t("SIRET is required")),
    company_address: yup.string().required(t("Company address is required")),
    jobTitle: yup.string().required(t("Job title is required")),
    seniority: yup.date().required(t("Seniority is required")),
  });

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [hasNewWordOrSearch, setHasNewWordOrSearch] = useState(false);
  const [open, setOpen] = useState(false);
  const [openJoyrideActivate, setOpenJoyrideActivate] = useState(false);
  const [word, setWord] = useState("");
  const [theme, setTheme] = useState("");
  const [wordList, setWordList] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [isVisibleMessageAdress, setIsVisibleMessageAdress] = useState(false);
  const [hasFetchedMe, setHasFetchedMe] = useState(false);
  const [profilePicture, setProfilePicture] = useState(
    localStorage.getItem("avatar") && localStorage.getItem("avatar") !== "null"
      ? localStorage.getItem("avatar")
      : "null"
  );
  const [newProfilePicture, setNewProfilePicture] = useState("");
  const [valueAddress, setValueAddress] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  const handleProfilePictureChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePicture(reader.result);
        setNewProfilePicture(file);
      };
      reader.readAsDataURL(file);
    }
  };

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, dirtyFields },
    resetField,
  } = useForm({
    resolver: yupResolver(schema),
  });

  const fetchMe = async () => {
    const { data } = await axiosInstance.get(
      `https://api-dev.seeks.biz/users/me/`
    );
    setValueAddress({
      label: data[0].address,
      city: data[0].city,
      postal_code: data[0].postal_code,
      region: data[0].region,
      x: data[0].longitude,
      y: data[0].latitude,
    });
    resetField("first_name", { defaultValue: data[0].first_name });
    resetField("last_name", { defaultValue: data[0].last_name });
    // resetField("password", { defaultValue: "" });
    resetField("email", { defaultValue: data[0].email });
    resetField("company_name", { defaultValue: data[0].company_name });
    resetField("company_address", { defaultValue: data[0].address });
    resetField("siret", { defaultValue: data[0].siret });
    resetField("seniority", {
      defaultValue: new Date(data[0].job_seniority),
    });
    resetField("jobTitle", { defaultValue: data[0].position });
    setWordList(data[0].words.map((word) => ({ ...word, nbOccu: undefined })));

    localStorage.setItem("userId", data[0].id);
    localStorage.setItem("avatar", data[0].avatar);
    localStorage.setItem(
      "fullname",
      data[0].first_name + " " + data[0].last_name
    );
    localStorage.setItem("email", data[0].email);
    localStorage.setItem("words", JSON.stringify(data[0].words));
    localStorage.setItem("address", data[0].address);
    localStorage.setItem("longitude", data[0].longitude);
    localStorage.setItem("latitude", data[0].latitude);
    return data[0];
  };

  // const { data: me, isLoadingMe } = useQuery(["me"], () => fetchMe(), {
  //   enabled: !hasFetchedMe,
  // });

  useEffect(() => {
    fetchMe();
  }, []);

  const fetchCities = async (searchText) => {
    try {
      const { data } = await axios.get(
        `https://api-adresse.data.gouv.fr/search/?q=${searchText}&limit=5`
      );
      return data.features.map((address) => {
        return {
          label: address.properties.label,
          x: address.geometry.coordinates[0],
          y: address.geometry.coordinates[1],
          region:
            address.properties.context &&
            address.properties.context.split(", ").length === 1
              ? address.properties.context.split(", ")[1]
              : address.properties.context.split(", ")[2],
          city: address.properties.city,
          postal_code: address.properties.postcode
            ? address.properties.postcode
            : address.properties.citycode,
        };
      });
    } catch (error) {
      console.error("Error fetching cities:", error);
      return [];
    }
  };

  const { data: cities, isLoading } = useQuery(
    ["cities", searchText],
    () => fetchCities(searchText),
    {
      enabled: !!searchText,
    }
  );

  const mutationNbPosts = useMutation({
    mutationFn: (user) => {
      return axiosInstance.post(
        "https://api-dev.seeks.biz/socialmedia/howmanyposts/",
        user
      );
    },
    onError: (error) => {
      setNotify({
        isOpen: true,
        message: retrieveError(error.response.data),
        type: "error",
      });
      console.log("Erreur lors de la soumission du formulaire : ", error);
    },
    onSuccess: (data, variables) => {
      if (!variables.isSearch) {
        const newWord = {
          word,
          theme,
          nbOccu: data.data?.number_of_posts ? data.data?.number_of_posts : 0,
        };
        setWordList([...wordList, newWord]);
        setWord("");
        setTheme("");
        setOpen(false);
        // setNotify({
        //   isOpen: true,
        //   message: t("Word added successfully!"),
        //   type: "success",
        // });
      } else {
        const updatedWordList = wordList.map((word, index) => {
          if (index === variables.rowId) {
            return {
              ...word,
              word: variables.words[0].word,
              theme: variables.words[0].theme,
              nbOccu: data.data?.number_of_posts
                ? data.data?.number_of_posts
                : 0,
            };
          } else {
            return word;
          }
        });
        setWordList(updatedWordList);
      }

      setHasNewWordOrSearch(true);
    },
  });

  const handleOpenJoyrideActivate = () => {
    setOpenJoyrideActivate(true);
  };

  const handleCloseJoyrideActivate = () => {
    setOpenJoyrideActivate(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleValueAddress = async (value) => {
    // let coordonates = await fetchCities(event.target.textContent);
    setIsVisibleMessageAdress(false);
    setValueAddress(value);
    console.log(value);
  };

  const handleChangeTheme = async (e) => {
    setTheme(e.target.value);
  };

  const handleAddWord = async () => {
    if (word && theme) {
      mutationNbPosts.mutate({
        words: [
          {
            word: word,
            theme: theme,
          },
        ],
        latitude: valueAddress.y,
        longitude: valueAddress.x,
        region: valueAddress.region,
        isSearch: false,
        exclude_my_own_answers: true,
      });
    }
  };

  const handleSearchOccu = async (item, index) => {
    console.log(valueAddress);
    mutationNbPosts.mutate({
      words: [
        {
          word: item.word,
          theme: item.theme,
        },
      ],
      latitude: valueAddress.y,
      longitude: valueAddress.x,
      region: valueAddress.region,
      isSearch: true,
      rowId: index,
      exclude_my_own_answers: true,
    });
  };

  const handleDeleteWord = (id) => {
    console.log(id);
    let updatedWL = wordList.filter((elt, index) => index !== id);
    console.log(updatedWL);
    setWordList(updatedWL);
  };

  const mutation = useMutation({
    mutationFn: (user) => {
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      return axiosInstance.patch(
        "https://api-dev.seeks.biz/users/me/",
        user,
        config
      );
    },
    onError: (error) => {
      setNotify({
        isOpen: true,
        message: retrieveError(error.response.data),
        type: "error",
      });
    },
    onSuccess: (data, variables, context) => {
      updateAvatarValue(data.data.avatar);
      updateFullnameValue(data.data.first_name + " " + data.data.last_name);
      localStorage.setItem("avatar", data.data.avatar);
      localStorage.setItem(
        "fullname",
        data.data.first_name + " " + data.data.last_name
      );
      localStorage.setItem("email", data.data.email);
      localStorage.setItem("words", JSON.stringify(data.data.words));
      localStorage.setItem("address", data.data.address);
      localStorage.setItem("longitude", data.data.longitude);
      localStorage.setItem("latitude", data.data.latitude);
      setNotify({
        isOpen: true,
        message: t("Account updated successfully!"),
        type: "success",
      });
      fetchMe();
    },
  });

  const handleFormSubmit = async (data, event) => {
    if (valueAddress && valueAddress.x && valueAddress.y) {
      const formData = new FormData();
      formData.append("email", data.email);
      formData.append("first_name", data.first_name);
      formData.append("last_name", data.last_name);
      formData.append("position", data.jobTitle);
      formData.append("company_name", data.company_name);
      formData.append("siret", data.siret);
      formData.append("job_seniority", format(data.seniority, "yyyy-MM-dd"));
      formData.append("longitude", valueAddress.x);
      formData.append("latitude", valueAddress.y);
      formData.append("region", valueAddress.region);
      formData.append("city", valueAddress.city);
      formData.append("postal_code", valueAddress.postal_code);
      formData.append("address", valueAddress.label);

      if (data.password?.length > 0) {
        formData.append("password", data.password);
      }
      if (newProfilePicture && newProfilePicture !== "") {
        formData.append("avatar", newProfilePicture);
      }

      formData.append("words", JSON.stringify(wordList));

      mutation.mutate(formData);
    } else {
      setIsVisibleMessageAdress(true);
    }
  };

  const getInitials = () => {
    let fullname = localStorage.getItem("fullname");
    if (fullname) {
      return `${fullname.split(" ")[0][0]}${fullname.split(" ")[1][0]}`;
    }
  };

  const fileInputRef = useRef(null);

  const handleClickOnAvatar = () => {
    // Simuler le clic sur l'input de fichier lorsque l'utilisateur clique sur l'Avatar
    fileInputRef.current.click();
  };

  const handleActivateTutorial = () => {
    localStorage.setItem("autoSkipJoyride", "false");
    handleOpenJoyrideActivate();
  };

  return (
    <>
      <Container style={{ maxWidth: "77rem" }}>
        <form className="mt-4" onSubmit={handleSubmit(handleFormSubmit)}>
          <Row>
            <Col lg="4">
              <Card>
                <Card.Header className="card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">
                      <Trans>Your Settings</Trans>
                    </h4>
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="acc-edit">
                    <Controller
                      name="first_name"
                      key="first_name"
                      control={control}
                      render={({ field, onChange }) => (
                        <TextField
                          label={t("First name")}
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          {...field}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                    {errors.firstName && (
                      <span className="text-danger">
                        {errors.first_name.message}
                      </span>
                    )}
                    <Controller
                      name="last_name"
                      control={control}
                      key="last_name"
                      render={({ field }) => (
                        <TextField
                          label={t("Last name")}
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          {...field}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                    {errors.last_name && (
                      <span className="text-danger">
                        {errors.last_name.message}
                      </span>
                    )}
                    <Controller
                      name="email"
                      control={control}
                      key="email"
                      render={({ field }) => (
                        <TextField
                          label={t("Email")}
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          {...field}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                    {errors.email && (
                      <span className="text-danger">
                        {errors.email.message}
                      </span>
                    )}
                    <Controller
                      name="password"
                      control={control}
                      key="password"
                      render={({ field }) => (
                        <TextField
                          label={t("Password")}
                          type={showPassword ? "text" : "password"}
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          {...field}
                          InputLabelProps={{
                            shrink: true,
                          }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment
                                position="end"
                                style={{ marginRight: "8px" }}
                              >
                                <Tooltip
                                  title={
                                    <span>
                                      {t("Please select a password:")} <br />-{" "}
                                      {t("8 characters minimum")} <br />-{" "}
                                      {t(
                                        'At least one capital letter, one lowercase letter, one digit, one special character among !@#$%^&*(),.?":{}|<>+'
                                      )}{" "}
                                      <br />- {t("Not too common")}
                                    </span>
                                  }
                                >
                                  <HelpOutlineIcon color="primary" />
                                </Tooltip>
                                <IconButton
                                  aria-label="toggle password visibility"
                                  onClick={handleClickShowPassword}
                                  onMouseDown={handleMouseDownPassword}
                                  edge="end"
                                  size="large"
                                >
                                  {showPassword ? (
                                    <Visibility />
                                  ) : (
                                    <VisibilityOff />
                                  )}
                                </IconButton>
                              </InputAdornment>
                            ),
                          }}
                        />
                      )}
                    />
                    {errors.password && (
                      <span className="text-danger">
                        {errors.password.message}
                      </span>
                    )}

                    <div style={{ textAlign: "center", marginTop: "16px" }}>
                      {/* <Typography gutterBottom>
                        <Trans>Profile picture</Trans>
                      </Typography> */}
                      <label htmlFor="file-input" style={{ cursor: "pointer" }}>
                        <Avatar
                          src={profilePicture}
                          sx={{
                            bgcolor: stringToColor(),
                            width: 69,
                            height: 69,
                            margin: "auto",
                          }}
                        >
                          {profilePicture && profilePicture !== "null"
                            ? profilePicture
                            : getInitials()}
                        </Avatar>
                      </label>
                      <input
                        ref={fileInputRef}
                        style={{ display: "none" }}
                        id="file-input"
                        type="file"
                        accept="image/*"
                        onChange={(e) => handleProfilePictureChange(e)}
                      />
                    </div>
                    <br />

                    <ThemeScheme themeScheme={themeScheme}></ThemeScheme>
                    <br />
                    <div>
                      <Button
                        variant="contained"
                        color="primary"
                        onClick={handleActivateTutorial}
                        style={{
                          marginLeft: "auto",
                          marginRight: "auto",
                          display: "block",
                        }}
                        // disabled={wordList.length < 3}
                      >
                        <Trans>Reactivate tutorial</Trans>
                      </Button>
                    </div>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="4">
              <Card>
                <Card.Header className="card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">
                      <Trans>Your Company Settings</Trans>
                    </h4>
                  </div>
                </Card.Header>
                <Card.Body>
                  <div className="acc-edit">
                    <Controller
                      name="company_name"
                      key="company_name"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          label={t("Company name")}
                          key="companyName"
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          {...field}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                    {errors.company_name && (
                      <span className="text-danger">
                        {errors.company_name.message}
                      </span>
                    )}
                    <Controller
                      name="siret"
                      control={control}
                      key="siret"
                      render={({ field }) => (
                        <TextField
                          label={t("SIRET")}
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          {...field}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                    {errors.siret && (
                      <span className="text-danger">
                        {errors.siret.message}
                      </span>
                    )}
                    <Controller
                      name="company_address"
                      control={control}
                      key="company_address"
                      render={({ field }) => (
                        <Autocomplete
                          id="city-search"
                          options={cities || []}
                          // loading={isLoading}
                          freeSolo
                          onInputChange={(event, newInputValue) => {
                            setSearchText(newInputValue);
                          }}
                          value={valueAddress}
                          onChange={(event, value) => {
                            handleValueAddress(value);
                          }}
                          filterOptions={(options, { inputValue }) => options}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              label={t("Company address")}
                              variant="outlined"
                              {...field}
                              margin="normal"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              InputProps={{
                                ...params.InputProps,
                                endAdornment: (
                                  <>{params.InputProps.endAdornment}</>
                                ),
                              }}
                            />
                          )}
                        />
                      )}
                    />
                    {errors.company_address && (
                      <span className="text-danger">
                        {errors.company_address.message}
                      </span>
                    )}
                    {isVisibleMessageAdress && (
                      <span className="text-danger">
                        <Trans>Please search for an address and pick one</Trans>
                      </span>
                    )}
                    <Controller
                      name="jobTitle"
                      control={control}
                      key="jobTitle"
                      render={({ field }) => (
                        <TextField
                          label={t("Job title")}
                          variant="outlined"
                          fullWidth
                          margin="normal"
                          {...field}
                          InputLabelProps={{
                            shrink: true,
                          }}
                        />
                      )}
                    />
                    {errors.jobTitle && (
                      <span className="text-danger">
                        {errors.jobTitle.message}
                      </span>
                    )}
                    <Controller
                      name="seniority"
                      control={control}
                      key="seniority"
                      render={({ field }) => (
                        <LocalizationProvider
                          dateAdapter={AdapterDateFns}
                          adapterLocale={
                            localStorage.getItem("i18nextLng") === "en"
                              ? enGB
                              : fr
                          }
                        >
                          <DemoContainer components={["DatePicker"]}>
                            <DesktopDatePicker
                              {...field}
                              format="dd/MM/yyyy"
                              sx={{
                                width: "100%",
                              }}
                              margin="normal"
                              disableFuture={true}
                              label={t("Seniority in position")}
                              value={field.value || null}
                              onChange={(date) => field.onChange(date)}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  InputLabelProps={{
                                    shrink: true,
                                  }}
                                />
                              )}
                            />
                          </DemoContainer>
                        </LocalizationProvider>
                      )}
                    />
                    {errors.seniority && (
                      <span className="text-danger">
                        {errors.seniority.message}
                      </span>
                    )}

                    {/* <Button type="reset" className="btn bg-soft-danger">
                      <Trans>Cancel</Trans>
                    </Button> */}
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg="4">
              <Card>
                <Card.Header className="card-header d-flex justify-content-between">
                  <div className="header-title">
                    <h4 className="card-title">
                      <Trans>Your words and packages</Trans>
                    </h4>
                  </div>
                </Card.Header>
                <Card.Body>
                  <span>
                    <div>
                      <span className="text-center">
                        <p>
                          <Trans>Your package:</Trans>

                          <Trans> Basic </Trans>
                          <Trans>(3 words)</Trans>
                        </p>

                        <p>
                          <Trans>Your option(s):</Trans>

                          <Trans>None</Trans>
                        </p>

                        <p>
                          <Tooltip
                            title={t(
                              "When a request is posted by a member, SEEKS will only forward it to you if one of your words appears in it. Choose them carefully to ensure that you respond to a maximum number of requests."
                            )}
                          >
                            <HelpOutlineIcon color="primary" />
                          </Tooltip>
                        </p>
                      </span>
                      <div>
                        <Button
                          onClick={handleOpen}
                          variant="contained"
                          color="primary"
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                            display: "block",
                          }}
                          id="buttonAddWords"
                        >
                          <Trans>Add a word/business sector</Trans>
                        </Button>
                      </div>
                    </div>
                    <TableContainer>
                      <Table>
                        <TableHead>
                          <TableRow>
                            <TableCell>
                              <Trans>Word</Trans>
                            </TableCell>
                            <TableCell>
                              <Trans>Business sector</Trans>
                            </TableCell>
                            {hasNewWordOrSearch ? (
                              <TableCell sx={{ width: "5%" }} align="right">
                                <Trans>Posts found</Trans>
                              </TableCell>
                            ) : null}
                            <TableCell></TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {wordList.map((item, index) => (
                            <TableRow key={index}>
                              <TableCell>{item.word}</TableCell>
                              <TableCell>{item.theme}</TableCell>
                              {hasNewWordOrSearch ? (
                                <TableCell sx={{ width: "5%" }} align="right">
                                  {typeof item.nbOccu === "number" ? (
                                    <Chip
                                      label={item.nbOccu}
                                      color={
                                        item.nbOccu > 4 ? "primary" : "error"
                                      }
                                    />
                                  ) : null}
                                </TableCell>
                              ) : null}
                              <TableCell>
                                <div
                                  style={{ display: "flex", float: "right" }}
                                >
                                  {item.nbOccu !== undefined ? null : (
                                    <Tooltip
                                      title={t("Verify number of posts")}
                                    >
                                      <IconButton
                                        style={{ float: "right" }}
                                        onClick={() => {
                                          handleSearchOccu(item, index);
                                        }}
                                      >
                                        <SearchIcon />
                                      </IconButton>
                                    </Tooltip>
                                  )}
                                  <IconButton
                                    style={{ float: "right" }}
                                    onClick={() => {
                                      handleDeleteWord(index);
                                    }}
                                  >
                                    <DeleteIcon />
                                  </IconButton>
                                </div>
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </span>
                </Card.Body>
              </Card>
            </Col>
            <div>
              <Button
                variant="contained"
                color="primary"
                // style={{ float: "center" }}
                type="submit"
                style={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  display: "block",
                  width: "100%",
                }}
                // disabled={wordList.length < 3}
              >
                <Trans>Validate changes</Trans>
              </Button>
            </div>
          </Row>
        </form>
      </Container>
      <br />
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>
          <Trans>Add a word/business sector</Trans>
        </DialogTitle>
        <DialogContent>
          <TextField
            label={t("Word")}
            variant="outlined"
            fullWidth
            value={word}
            onChange={(e) => {
              const inputValue = e.target.value;
              if (!inputValue.includes(" ")) {
                setWord(inputValue);
              }
            }}
            style={{ marginTop: "8px" }}
          />
          <FormControl fullWidth style={{ marginTop: "16px" }}>
            <InputLabel>
              <Trans>Business sector</Trans>
            </InputLabel>
            <Select
              fullWidth
              value={theme}
              label="Business sector"
              onChange={handleChangeTheme}
              endAdornment={
                <InputAdornment
                  position="end"
                  style={{ position: "absolute", right: "36px" }}
                >
                  <Tooltip
                    title={t(
                      "These words are linked to sectors, so that someone looking for bass fishing equipment 🎣, does not see his request sent to a luthier 🎸."
                    )}
                  >
                    <HelpOutlineIcon color="primary" />
                  </Tooltip>
                </InputAdornment>
              }
            >
              {listThemes.map((sector) => (
                <MenuItem value={sector} key={sector}>
                  {sector}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <Button
            onClick={handleAddWord}
            variant="contained"
            className="btn btn-primary me-2"
            style={{
              marginLeft: "auto",
              marginTop: "12px",
              display: "block",
            }}
            disabled={word.length < 2}
          >
            <Trans>Add</Trans>
          </Button>
        </DialogContent>
      </Dialog>
      <Notification notify={notify} setNotify={setNotify} />

      <Dialog open={openJoyrideActivate} onClose={handleCloseJoyrideActivate}>
        <DialogTitle>
          <Trans>Tutorial reactivated</Trans>
        </DialogTitle>
        <DialogContent>
          <Trans>
            You reactived the tutorial. To see it again, go back to the home
            page and reload it.
          </Trans>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseJoyrideActivate}>
            <Trans>OK</Trans>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default UserAccountSetting;
