import React, { useState } from "react";
import { Dropdown, Card } from "react-bootstrap";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import PostAddIcon from "@mui/icons-material/PostAdd";
import TimeAgo from "react-timeago";
import frenchStrings from "react-timeago/lib/language-strings/fr";
import enStrings from "react-timeago/lib/language-strings/en";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import { CircularProgress, Badge } from "@mui/material";
import axiosInstance from "../../../useApi";
import { useQuery } from "@tanstack/react-query";
import { Trans } from "react-i18next";
import CustomToggle from "../../../components/dropdowns";
import { useNavigate } from "react-router-dom";

const MyNotifications = () => {
  const formatter = buildFormatter(frenchStrings);
  const formatterBis = buildFormatter(enStrings);

  const [numberNewNotifs, setNumberNewNotifs] = useState(0);

  const navigate = useNavigate();

  const fetchMyNotifications = async () => {
    const { data } = await axiosInstance.get("/notifications/");
    setNumberNewNotifs(
      data.results.filter((notification) => !notification.is_read).length
    );
    return data;
  };

  const { data: notifications } = useQuery(
    ["notifications"],
    () => fetchMyNotifications(),
    {
      retry: false,
    }
  );

  const handleNotificationClick = (notification) => {
    let searchString = "/myPost/";
    if (notification.kind === "newAnswer") {
      const indexOfSearchString = notification.link.indexOf(searchString);
      if (indexOfSearchString !== -1) {
        const partAfterMyPost =
          notification.link.substring(indexOfSearchString);
        console.log(partAfterMyPost);
        navigate(partAfterMyPost);
      } else {
        console.log("'/myPost' not found in the string.");
      }
    } else {
      navigate(notification.link);
    }
  };

  return (
    <>
      <Dropdown as="li" className="nav-item ">
        <Dropdown.Toggle
          href="#"
          as={CustomToggle}
          variant="search-toggle d-flex align-items-center"
        >
          {numberNewNotifs > 0 ? (
            <Badge badgeContent={numberNewNotifs} color="primary">
              <i className="material-symbols-outlined">notifications</i>
            </Badge>
          ) : (
            <i className="material-symbols-outlined">notifications</i>
          )}
        </Dropdown.Toggle>
        <Dropdown.Menu className="sub-drop">
          <Card className="shadow-none m-0">
            <Card.Header className="d-flex justify-content-between bg-primary">
              <div className="header-title bg-primary">
                <h5 className="mb-0 text-white ">
                  <Trans>Notifications</Trans>
                </h5>
              </div>
              <small className="badge  bg-light text-dark">
                {numberNewNotifs}
              </small>
            </Card.Header>
            <Card.Body
              className="p-0"
              style={{ maxHeight: "69vh", overflowY: "auto" }}
            >
              {!notifications || notifications.length <= 0 ? (
                <div className="col-sm-12 text-center">
                  <CircularProgress />
                </div>
              ) : notifications.results && notifications.results.length <= 0 ? (
                <div style={{ textAlign: "center", fontSize: "18px" }}>
                  <Trans>No notification found</Trans>
                </div>
              ) : (
                notifications?.results.map((notification, index) => (
                  <div
                    className={`${"iq-sub-card"} ${
                      notification.is_read ? null : "iq-sub-card-read"
                    }`}
                    key={notification.id}
                    onClick={() => handleNotificationClick(notification)}
                    style={{ cursor: "pointer" }}
                  >
                    <div className="d-flex align-items-center">
                      {notification.is_read ? null : (
                        <div
                          style={{
                            backgroundColor: "#6da7f2",
                            width: "10px",
                            height: "8px",
                            borderRadius: "50%",
                            marginLeft: "-10px",
                            marginRight: "3px",
                          }}
                        />
                      )}
                      <div>
                        {notification.kind === "newAnswer" ? (
                          <QuestionAnswerIcon />
                        ) : (
                          <PostAddIcon />
                        )}
                      </div>
                      <div className="ms-3 w-100">
                        <h6 className="mb-0 ">{notification.message}</h6>
                        <div className="d-flex justify-content-between align-items-center">
                          <small className="float-right font-size-12">
                            <TimeAgo
                              date={notification.created_at}
                              formatter={
                                localStorage.getItem("i18next") === "fr"
                                  ? formatterBis
                                  : formatter
                              }
                            />
                          </small>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </Card.Body>
          </Card>
        </Dropdown.Menu>
      </Dropdown>
    </>
  );
};

export default MyNotifications;
