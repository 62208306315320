import React, { useState } from "react";
import { Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import { Divider } from "@mui/material";
import SwiperCore, { Navigation, Autoplay } from "swiper";
// Import Swiper styles
import "swiper/swiper-bundle.min.css";

import logo from "../../../assets/images/logo-blanc-bleu.png";
import login1 from "../../../assets/images/AdobeStock_635085758.jpeg";
import login2 from "../../../assets/images/AdobeStock_565684745.jpeg";
import login3 from "../../../assets/images/AdobeStock_408977966.jpeg";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const Swipper = () => {
  const history = useNavigate();

  const { t, i18n } = useTranslation();

  return (
    <>
      <div className="sign-in-detail text-white">
        {/* <Link className="sign-in-logo mb-5" to="/auth/sign-in">
          <Image src={logo} className="img-fluid" alt="logo" />
        </Link> */}
        <div className="sign-slider overflow-hidden">
          <h1
            className="text-center"
            style={{
              color: "#f7f7f8",

              marginRight: "80px",
              marginTop: "200px",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  marginBottom: "1rem",
                  justifyContent: "center",
                  width: "100%",
                }}
              >
                <Link
                  to="/auth/sign-in"
                  style={{ width: "9.52vw", marginRight: "1rem" }}
                >
                  <img
                    src={logo}
                    className="img-fluid"
                    alt="logo"
                    style={{
                      maxWidth: "100%",
                      height: "auto",
                      fontSize: "2vw",
                    }}
                  />
                </Link>
                <b style={{ fontSize: "5.3vw" }}>SEEKS</b>
              </div>
              <Divider
                style={{
                  background: "white",
                  borderBottomWidth: 2,
                  opacity: 1,
                  width: "100%",
                  marginBottom: "1rem",
                }}
              />
              <div
                style={{
                  width: "100%",
                }}
              >
                <b style={{ fontSize: "2.4vw" }}>RÉSEAU SOCIAL BUSINESS</b>
              </div>
            </div>
          </h1>
          {/* <Swiper
            spaceBetween={30}
            centeredSlides={true}
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            className="list-inline m-0 p-0 "
          >
            <SwiperSlide>
              <Image
                src={login1}
                className="img-fluid mb-4"
                alt="logo"
                style={{ borderRadius: "5%" }}
              />
              <h4
                className="mb-1 text-white"
                style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
              >
                <Trans>
                  SEEKS, le premier réseau social entièrement dédié au business
                </Trans>
              </h4>
              <p></p>
            </SwiperSlide>
            <SwiperSlide>
              <Image
                src={login2}
                className="img-fluid mb-4"
                alt="logo"
                style={{ borderRadius: "5%", width: "80%", height: "auto" }}
              />
              <h4
                className="mb-1 text-white"
                style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
              >
                <Trans>
                  The adage 'Client is king' has never been so true. You don't
                  have to seek anymore, just to choose.
                </Trans>
              </h4>
              <p></p>
            </SwiperSlide>
            <SwiperSlide>
              <Image
                src={login3}
                className="img-fluid mb-4"
                alt="logo"
                style={{ borderRadius: "5%" }}
              />
              <h4
                className="mb-1 text-white"
                style={{ textShadow: "2px 2px 4px rgba(0, 0, 0, 0.5)" }}
              >
                <Trans>
                  The world is divided in two categories, sellers and buyers.
                  With SEEKS you are both.
                </Trans>
              </h4>
              <p></p>
            </SwiperSlide>
          </Swiper> */}
        </div>
      </div>
    </>
  );
};

export default Swipper;
