import React from "react";

// auth
import ConfirmMail from "../views/dashboard/auth/confirm-mail";
import Recoverpw from "../views/dashboard/auth/recoverpw";
import ConfirmAccountCreate from "../views/dashboard/auth/confirm-account-create";

import ConfirmRecoverPw from "../views/dashboard/auth/confirmRecoverPw";
import GetTokenActivation from "../views/dashboard/auth/getTokenActivation";

import SignIn from "../views/dashboard/auth/sign-in";
import SignUp from "../views/dashboard/auth/sign-up";

// errors
import Error404 from "../views/dashboard/errors/error404";
import Error500 from "../views/dashboard/errors/error500";

//extrpages
import Maintenance from "../views/dashboard/extrapages/maintenance";
import ComingSoon from "../views/dashboard/extrapages/comingsoon";

export const SimpleRouter = [
  {
    path: "auth/activation",
    element: <ConfirmMail />,
  },
  {
    path: "auth/activation/getToken",
    element: <GetTokenActivation />,
  },
  {
    path: "auth/recoverpw",
    element: <Recoverpw />,
  },
  {
    path: "auth/confirmRecoverPw",
    element: <ConfirmRecoverPw />,
  },
  {
    path: "auth/sign-in",
    element: <SignIn />,
  },
  {
    path: "auth/sign-up",
    element: <SignUp />,
  },
  {
    path: "auth/mail-sent",
    element: <ConfirmAccountCreate />,
  },
  {
    path: "errors/error404",
    element: <Error404 />,
  },
  {
    path: "*",
    element: <Error404 />,
  },
  {
    path: "errors/error500",
    element: <Error500 />,
  },
  {
    path: "extra-pages/pages-maintenance",
    element: <Maintenance />,
  },
  {
    path: "extra-pages/pages-comingsoon",
    element: <ComingSoon />,
  },
];
