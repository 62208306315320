import React from "react";
import Index from "../views/dashboard/index";

//app
import UserProfile from "../views/dashboard/app/user-profile";
import Notification from "../views/dashboard/app/notification";
import UserAccountSetting from "../views/dashboard/app/user-account-setting";

// Email
import Email from "../views/dashboard/email/email";
import EmailCompose from "../views/dashboard/email/email-compose";
import MyPostDetails from "../views/dashboard/app/my-post-details";
import PostDetails from "../views/dashboard/app/post-details";
import { Navigate } from "react-router-dom";
// extrapages
import Pricing from "../views/dashboard/extrapages/pricing";
import Pricing1 from "../views/dashboard/extrapages/pricing1";
import Invoice from "../views/dashboard/extrapages/invoice";
import Faq from "../views/dashboard/extrapages/faq";
import PrivacyPolicy from "../views/dashboard/extrapages/privacy-policy";
import TermsofService from "../views/dashboard/extrapages/terms-of-service";
import BlankPage from "../views/dashboard/extrapages/blankpage";
import Admin from "../views/dashboard/app/admin";
import jwt_decode from "jwt-decode";

const isAuthenticated = () => {
  var isExpired = false;
  const token = localStorage.getItem("refresh_token");
  if (token) {
    var decodedToken = jwt_decode(token);
    var dateNow = new Date();
    if (
      decodedToken.exp < parseInt(dateNow.getTime().toString().slice(0, -3))
    ) {
      isExpired = true;
    }
    return !isExpired;
  } else {
    return false;
  }
};

export const DefaultRouter = [
  {
    path: "/",
    element: isAuthenticated() ? <Index /> : <Navigate to="/auth/sign-in" />,
  },
  {
    path: "dashboard/app/profile",
    element: isAuthenticated() ? (
      <UserProfile />
    ) : (
      <Navigate to="/auth/sign-in" />
    ),
  },
  {
    path: "myPost/:idPost",
    element: isAuthenticated() ? (
      <MyPostDetails />
    ) : (
      <Navigate to="/auth/sign-in" state={{ from: window.location.pathname }} />
    ),
  },
  {
    path: "post/:idPost",
    element: isAuthenticated() ? (
      <PostDetails />
    ) : (
      <Navigate to="/auth/sign-in" state={{ from: window.location.pathname }} />
    ),
  },
  {
    path: "dashboard/app/user-account-setting",
    element: isAuthenticated() ? (
      <UserAccountSetting />
    ) : (
      <Navigate to="/auth/sign-in" />
    ),
  },

  {
    path: "dashboard/app/notification",
    element: <Notification />,
  },
  {
    path: "dashboard/Email/email",
    element: <Email />,
  },
  {
    path: "dashboard/Email/email-compose",
    element: <EmailCompose />,
  },
  {
    path: "dashboard/extrapages/pages-pricing",
    element: <Pricing />,
  },
  {
    path: "dashboard/extrapages/pages-pricing-one",
    element: <Pricing1 />,
  },
  {
    path: "dashboard/extrapages/pages-invoice",
    element: <Invoice />,
  },
  {
    path: "dashboard/extrapages/pages-faq",
    element: <Faq />,
  },
  {
    path: "dashboard/extrapages/privacy-policy",
    element: <PrivacyPolicy />,
  },
  {
    path: "dashboard/extrapages/terms-of-service",
    element: <TermsofService />,
  },
  {
    path: "dashboard/extrapages/blankpage",
    element: <BlankPage />,
  },
  {
    path: "dashboard/app/admin",
    element: <Admin />,
  },
];
