import { yupResolver } from "@hookform/resolvers/yup";
import AddPhotoAlternateIcon from "@mui/icons-material/AddPhotoAlternate";
import DeleteIcon from "@mui/icons-material/Delete";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import VideoLibraryIcon from "@mui/icons-material/VideoLibrary";
import {
  Autocomplete,
  Avatar,
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import FormControl from "@mui/material/FormControl";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { useMutation, useQuery } from "@tanstack/react-query";
import axios from "axios";
import { format } from "date-fns";
import { enGB, fr } from "date-fns/locale";
import { useContext, useEffect, useState } from "react";
import { Col, Container, Modal, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { Trans, useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";
import * as yup from "yup";
import Notification from "../../Notification";
import Card from "../../components/Card";
import { HeaderContext } from "../../components/partials/dashboard/HeaderStyle/headerContext";
import axiosInstance from "../../useApi";
import {
  getInitials,
  listThemes,
  retrieveError,
  stringToColor,
} from "../../utilities/constants";
import ListPosts from "./app/list-posts";

const regions = [
  "Auvergne-Rhône-Alpes",
  "Bourgogne-Franche-Comté",
  "Bretagne",
  "Centre-Val de Loire",
  "Corse",
  "Grand Est",
  "Hauts-de-France",
  "Île-de-France",
  "Normandie",
  "Nouvelle-Aquitaine",
  "Occitanie",
  "Pays de la Loire",
  "Provence-Alpes-Côte d'Azur",
];

const distances = [1, 2, 5, 10, 20, 30, 50, 100, 200];

const Index = () => {
  const { t, i18n } = useTranslation();

  const navigate = useNavigate();

  const [theme, setTheme] = useState("Alimentation et Boissons");
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [isVisibleMessageAdress, setIsVisibleMessageAdress] = useState(false);
  const [valueRadioLocalization, setValueRadioLocalization] = useState("local");
  const [files, setFiles] = useState([]);
  const [valueAddress, setValueAddress] = useState("");
  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [selectedRegion, setSelectedRegion] = useState("Auvergne-Rhône-Alpes");

  const [searchText, setSearchText] = useState("");

  const [localTrue, setLocalTrue] = useState(true);

  const [selectedDistance, setSelectedDistance] = useState("1");

  const [profilePictureUrl, setProfilePictureUrl] = useState(
    localStorage.getItem("avatar") && localStorage.getItem("avatar") !== "null"
      ? localStorage.getItem("avatar")
      : "null"
  );

  const schema = yup.object().shape({
    textPost: yup.string().required(t("Text post is required")),
    limitDate: yup.date().required(t("Limit date is required")),
    address: yup
      .string()
      .when("$localTrue", (val, schema) =>
        localTrue ? schema.required(t("Address is required")) : schema
      ),
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, dirtyFields },
    resetField,
    getValues,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      address: localStorage.getItem("address"),
    },
  });

  const handleRegionChange = (event) => {
    setSelectedRegion(event.target.value);
  };

  const handleDistanceChange = (event) => {
    setSelectedDistance(event.target.value);
  };

  const { headerValue } = useContext(HeaderContext);

  const fetchCities = async (searchText) => {
    try {
      const { data } = await axios.get(
        `https://api-adresse.data.gouv.fr/search/?q=${searchText}&limit=10`
      );
      return data.features.map((address) => {
        return {
          label: address.properties.label,
          x: address.geometry.coordinates[0],
          y: address.geometry.coordinates[1],
        };
      });
    } catch (error) {
      console.error("Error fetching cities:", error);
      return [];
    }
  };

  const { data: cities, isLoading } = useQuery(
    ["cities", searchText],
    () => fetchCities(searchText),
    {
      enabled: !!searchText,
    }
  );

  useEffect(() => {
    const fetchLocalStorageData = async () => {
      const storedAddress = localStorage.getItem("address");
      const storedLongitude = localStorage.getItem("longitude");
      const storedLatitude = localStorage.getItem("latitude");

      if (storedAddress && storedLongitude && storedLatitude) {
        setValueAddress({
          label: storedAddress,
          x: storedLongitude,
          y: storedLatitude,
        });
        setSearchText(storedAddress);
      }
    };

    fetchLocalStorageData();
  }, []);

  const handleDeleteFile = (id) => {
    let updatedFL = files.filter((elt, index) => index !== id);
    setFiles(updatedFL);
  };

  const handleRadioChange = (event) => {
    if (event.target.value === "local") {
      setLocalTrue(true);
    } else {
      setLocalTrue(false);
    }
    setValueRadioLocalization(event.target.value);
  };

  const handleImageChange = (e) => {
    const selectedFiles = e.target.files;
    setFiles([...files, ...selectedFiles]);
  };

  const handleChangeTheme = async (e) => {
    setTheme(e.target.value);
  };

  const handleValueAddress = async (value) => {
    setIsVisibleMessageAdress(false);
    setValueAddress(value);
  };

  const mutationCreatePost = useMutation({
    mutationFn: (post) => {
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      };
      return axiosInstance.post(
        "https://api-dev.seeks.biz/socialmedia/myposts/",
        post,
        config
      );
    },
    onError: (error) => {
      setNotify({
        isOpen: true,
        message: retrieveError(error.response.data),
        type: "error",
      });
      console.log("Erreur lors de la soumission du formulaire : ", error);
    },
    onSuccess: (data, variables, context) => {
      setNotify({
        isOpen: true,
        message: t("Post created successfully!"),
        type: "success",
      });
      handleClose();
    },
  });

  const handleFormSubmit = async (data, event) => {
    if (valueRadioLocalization === "local") {
      if (valueAddress && valueAddress.x && valueAddress.y) {
        const formData = new FormData();
        formData.append("content", getValues("textPost"));
        formData.append("business_sector", theme);
        formData.append(
          "valid_until",
          format(getValues("limitDate"), "yyyy-MM-dd")
        );
        formData.append("location_type", valueRadioLocalization);

        formData.append("location", valueAddress.label);
        formData.append("location_radius", selectedDistance);
        formData.append("longitude", valueAddress.x);
        formData.append("latitude", valueAddress.y);
        const imageFiles = files.filter((elt) => elt.type.startsWith("image"));
        const nonImageFiles = files.filter(
          (elt) => !elt.type.startsWith("image")
        );

        if (imageFiles.length > 0) {
          imageFiles.forEach((imageFile, index) => {
            formData.append(`images`, imageFile);
          });
        }

        if (nonImageFiles.length > 0) {
          nonImageFiles.forEach((nonImageFile, index) => {
            formData.append(`attached_files`, nonImageFile);
          });
        }
        formData.append("author", localStorage.getItem("userId"));
        mutationCreatePost.mutate(formData);
      } else {
        setIsVisibleMessageAdress(true);
      }
    } else {
      const formData = new FormData();
      formData.append("content", getValues("textPost"));
      formData.append("business_sector", theme);
      formData.append(
        "valid_until",
        format(getValues("limitDate"), "yyyy-MM-dd")
      );
      formData.append("location_type", valueRadioLocalization);
      if (valueRadioLocalization === "régional") {
        formData.append("location", selectedRegion);
      } else {
        formData.append("location", "France");
      }
      const imageFiles = files.filter((elt) => elt.type.startsWith("image"));
      const nonImageFiles = files.filter(
        (elt) => !elt.type.startsWith("image")
      );

      if (imageFiles.length > 0) {
        imageFiles.forEach((imageFile, index) => {
          formData.append(`images`, imageFile);
        });
      }

      if (nonImageFiles.length > 0) {
        nonImageFiles.forEach((nonImageFile, index) => {
          formData.append(`attached_files`, nonImageFile);
        });
      }
      formData.append("author", localStorage.getItem("userId"));
      mutationCreatePost.mutate(formData);
    }
  };

  const handleScrollTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <>
      <div id="content-page" className="content-page">
        <Container>
          <Row>
            <Col lg={12} className="row m-0 p-0">
              <Col sm={12}>
                <Card
                  id="post-modal-data"
                  className="card-block card-stretch card-height"
                >
                  <div className="card-header d-flex justify-content-between">
                    <div className="header-title">
                      <h4 className="card-title">
                        <Trans>Create Post</Trans>
                      </h4>
                    </div>
                  </div>
                  <Card.Body>
                    <div className="d-flex align-items-center">
                      <div className="user-img">
                        <Avatar
                          src={profilePictureUrl}
                          sx={{ bgcolor: stringToColor() }}
                        >
                          {profilePictureUrl && profilePictureUrl !== "null"
                            ? profilePictureUrl
                            : getInitials()}
                        </Avatar>
                      </div>
                      <form
                        className="post-text ms-3 w-100 "
                        onClick={handleShow}
                        id="formStartPost"
                      >
                        <input
                          type="text"
                          className="form-control rounded"
                          placeholder={t("Start a post")}
                          style={{ border: "none" }}
                        />
                      </form>
                    </div>
                    <hr></hr>
                  </Card.Body>
                  <Modal
                    size="lg"
                    className="fade"
                    id="post-modal"
                    onHide={handleClose}
                    show={show}
                    style={{
                      paddingTop: "112px",
                      paddingBottom: "112px",
                    }}
                  >
                    <Modal.Header className="d-flex justify-content-between">
                      <Modal.Title id="post-modalLabel">
                        <Trans>Create Post</Trans>
                      </Modal.Title>
                      <Tooltip
                        title={t(
                          "SEEKS advises you to provide as much detail as possible in your search. Quantity, size, weight, color, budget, timing, circumstances, photo, document, links... With the 3-word system, the more detailed your search, the greater the chance of being contacted by the right person."
                        )}
                      >
                        <HelpOutlineIcon color="primary" />
                      </Tooltip>
                      <Link to="#" className="lh-1" onClick={handleClose}>
                        <span className="material-symbols-outlined">close</span>
                      </Link>
                    </Modal.Header>
                    <Modal.Body
                      style={{
                        overflowY: "auto",
                        maxHeight: "calc(100vh - 210px)",
                      }}
                    >
                      <form
                        className="mt-4"
                        onSubmit={handleSubmit(handleFormSubmit)}
                      >
                        <div className="d-flex align-items-center">
                          <div className="user-img">
                            <Avatar
                              src={profilePictureUrl}
                              sx={{ bgcolor: stringToColor() }}
                            >
                              {profilePictureUrl && profilePictureUrl !== "null"
                                ? profilePictureUrl
                                : getInitials()}
                            </Avatar>
                          </div>
                          <Controller
                            name="textPost"
                            key="textPost"
                            control={control}
                            render={({ field, onChange }) => (
                              <TextField
                                fullWidth
                                style={{ marginLeft: "8px" }}
                                label={<Trans>What are you looking for?</Trans>}
                                // onChange={handleTextChange}
                                type="text"
                                {...field}
                                // value={text}
                                variant="outlined"
                                multiline
                              />
                            )}
                          />
                          <input
                            type="file"
                            className="custom-file-input"
                            id="logo"
                            accept="image/*, video/*, .pdf"
                            multiple
                            onChange={handleImageChange}
                            style={{ display: "none" }}
                          />
                          <label htmlFor="logo">
                            <AddPhotoAlternateIcon
                              style={{ fontSize: "2rem" }}
                            />
                          </label>
                        </div>
                        {errors.textPost && (
                          <span
                            className="text-danger"
                            style={{ marginLeft: "66px" }}
                          >
                            {errors.textPost.message}
                          </span>
                        )}
                        <div style={{ display: "flex", marginTop: "12px" }}>
                          <Grid container spacing={2}>
                            {files.map((file, index) =>
                              file.type.startsWith("image") ? (
                                <Grid item key={file}>
                                  <Paper
                                    elevation={3}
                                    sx={{
                                      position: "relative",
                                      ":hover": {
                                        "& .deleteButton": {
                                          display: "block",
                                        },
                                      },
                                    }}
                                  >
                                    <img
                                      src={URL.createObjectURL(file)}
                                      alt={`preview-${index}`}
                                      className="avatar-60 img-fluid"
                                    />
                                    <IconButton
                                      color="error"
                                      onClick={() => handleDeleteFile(index)}
                                      sx={{
                                        position: "absolute",
                                        top: "8px",
                                        right: "8px",
                                        display: "none",
                                      }}
                                      className="deleteButton"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Paper>
                                </Grid>
                              ) : null
                            )}
                          </Grid>
                        </div>

                        <div style={{ display: "flex", marginTop: "12px" }}>
                          <Grid container spacing={2}>
                            {files.map((file, index) =>
                              file.type.startsWith("video") ? (
                                <Grid item key={file}>
                                  <Box
                                    elevation={3}
                                    sx={{
                                      position: "relative",
                                      ":hover": {
                                        "& .deleteButton": {
                                          display: "block",
                                        },
                                      },
                                    }}
                                  >
                                    <VideoLibraryIcon
                                      color="error"
                                      sx={{ fontSize: 64 }}
                                    />
                                    <Tooltip title={file.name}>
                                      <Typography
                                        variant="body1"
                                        sx={{
                                          maxWidth: 120,
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        {file.name}
                                      </Typography>
                                    </Tooltip>
                                    <IconButton
                                      color="error"
                                      onClick={() => handleDeleteFile(index)}
                                      sx={{
                                        position: "absolute",
                                        top: "8px",
                                        right: "8px",
                                        display: "none",
                                      }}
                                      className="deleteButton"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Box>
                                </Grid>
                              ) : null
                            )}
                          </Grid>
                        </div>

                        <div style={{ display: "flex", marginTop: "12px" }}>
                          <Grid container spacing={2}>
                            {files.map((file, index) =>
                              file.type.startsWith("application/pdf") ? (
                                <Grid item key={file}>
                                  <Box
                                    elevation={3}
                                    sx={{
                                      position: "relative",
                                      ":hover": {
                                        "& .deleteButton": {
                                          display: "block",
                                        },
                                      },
                                    }}
                                  >
                                    <PictureAsPdfIcon
                                      color="error"
                                      sx={{ fontSize: 64 }}
                                    />
                                    <Tooltip title={file.name}>
                                      <Typography
                                        variant="body1"
                                        sx={{
                                          maxWidth: 120,
                                          overflow: "hidden",
                                          textOverflow: "ellipsis",
                                          whiteSpace: "nowrap",
                                        }}
                                      >
                                        {file.name}
                                      </Typography>
                                    </Tooltip>
                                    <IconButton
                                      color="error"
                                      onClick={() => handleDeleteFile(index)}
                                      sx={{
                                        position: "absolute",
                                        top: "8px",
                                        right: "8px",
                                        display: "none",
                                      }}
                                      className="deleteButton"
                                    >
                                      <DeleteIcon />
                                    </IconButton>
                                  </Box>
                                </Grid>
                              ) : null
                            )}
                          </Grid>
                        </div>

                        <hr />

                        <ul className="d-flex flex-wrap align-items-center list-inline m-0 p-0">
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              marginTop: "8px",
                            }}
                          >
                            <div>
                              <span
                                style={{
                                  marginRight: "10px",
                                }}
                              >
                                <h5 className="mb-0 d-inline-block">
                                  <Trans>
                                    Please choose a location for the visibility
                                    of your post
                                  </Trans>
                                </h5>
                              </span>
                              <FormControl>
                                <RadioGroup
                                  defaultValue="local"
                                  row
                                  name="radio-buttons-group"
                                  value={valueRadioLocalization}
                                  onChange={handleRadioChange}
                                  style={{
                                    marginTop: "-8px",
                                  }}
                                >
                                  <FormControlLabel
                                    value="local"
                                    control={<Radio />}
                                    label={t("Locale")}
                                  />
                                  <FormControlLabel
                                    value="régional"
                                    control={<Radio />}
                                    label={t("Region")}
                                  />
                                  <FormControlLabel
                                    value="national"
                                    control={<Radio />}
                                    label={t("National")}
                                  />
                                </RadioGroup>
                              </FormControl>
                            </div>
                            <div>
                              {valueRadioLocalization === "local" ? (
                                <Grid container spacing={3}>
                                  <Grid item md={8} xs={12}>
                                    <Controller
                                      name="address"
                                      control={control}
                                      key="address"
                                      render={({ field }) => (
                                        <Autocomplete
                                          id="address-search"
                                          options={cities || []}
                                          // loading={isLoading}
                                          freeSolo
                                          onInputChange={(
                                            event,
                                            newInputValue
                                          ) => {
                                            setSearchText(newInputValue);
                                          }}
                                          value={valueAddress}
                                          onChange={(event, value) => {
                                            handleValueAddress(value);
                                          }}
                                          filterOptions={(
                                            options,
                                            { inputValue }
                                          ) => options}
                                          renderInput={(params) => (
                                            <TextField
                                              {...params}
                                              label={t("Address")}
                                              variant="outlined"
                                              {...field}
                                              InputProps={{
                                                ...params.InputProps,
                                                endAdornment: (
                                                  <>
                                                    {
                                                      params.InputProps
                                                        .endAdornment
                                                    }
                                                  </>
                                                ),
                                              }}
                                            />
                                          )}
                                        />
                                      )}
                                    />
                                    {errors.address && (
                                      <span className="text-danger">
                                        {errors.address.message}
                                      </span>
                                    )}
                                    {isVisibleMessageAdress && (
                                      <span className="text-danger">
                                        <Trans>
                                          Please search for an address and pick
                                          one
                                        </Trans>
                                      </span>
                                    )}
                                  </Grid>
                                  <Grid item md={4} xs={12}>
                                    <Controller
                                      name="distanceAddress"
                                      key="distanceAddress"
                                      control={control}
                                      render={({ field, onChange }) => (
                                        <FormControl fullWidth>
                                          <InputLabel>
                                            <Trans>Perimeter (km)</Trans>
                                          </InputLabel>
                                          <Select
                                            fullWidth
                                            {...field}
                                            value={selectedDistance}
                                            onChange={handleDistanceChange}
                                            label={t("Perimeter (km)")}
                                          >
                                            {distances.map((distance) => (
                                              <MenuItem
                                                key={distance}
                                                value={distance}
                                              >
                                                {distance}
                                              </MenuItem>
                                            ))}
                                          </Select>
                                        </FormControl>
                                      )}
                                    />
                                  </Grid>
                                </Grid>
                              ) : valueRadioLocalization === "régional" ? (
                                <div>
                                  <Controller
                                    name="region"
                                    key="region"
                                    control={control}
                                    render={({ field, onChange }) => (
                                      <FormControl variant="outlined" fullWidth>
                                        <InputLabel id="region-select-label">
                                          <Trans>Region</Trans>
                                        </InputLabel>
                                        <Select
                                          labelId="region-select-label"
                                          id="region-select"
                                          {...field}
                                          value={selectedRegion}
                                          onChange={handleRegionChange}
                                          label={t("Region")}
                                        >
                                          {regions.map((region) => (
                                            <MenuItem
                                              key={region}
                                              value={region}
                                            >
                                              {region}
                                            </MenuItem>
                                          ))}
                                        </Select>
                                      </FormControl>
                                    )}
                                  />
                                </div>
                              ) : null}
                            </div>
                          </div>
                        </ul>

                        <hr />
                        {/* <ul className="d-flex flex-wrap align-items-center list-inline m-0 p-0 "> */}
                        {/* <div
                          // style={{
                          //   width: "100%",
                          // }}
                          style={{
                            marginLeft: "auto",
                            marginRight: "auto",
                          }}
                        > */}
                        <Grid container spacing={3}>
                          <Grid item md={6} xs={12}>
                            <Controller
                              name="businessSector"
                              key="businessSector"
                              control={control}
                              render={({ field, onChange }) => (
                                <FormControl
                                  fullWidth
                                  style={{ marginTop: "8px" }}
                                >
                                  <InputLabel>
                                    <Trans>Business sector</Trans>
                                  </InputLabel>
                                  <Select
                                    fullWidth
                                    value={theme}
                                    label="Business sector"
                                    onChange={handleChangeTheme}
                                    endAdornment={
                                      <InputAdornment
                                        position="end"
                                        style={{
                                          position: "absolute",
                                          right: "36px",
                                        }}
                                      >
                                        <Tooltip
                                          title={t(
                                            "These words are linked to sectors, so that someone looking for bass fishing equipment 🎣, does not see his request sent to a luthier 🎸."
                                          )}
                                        >
                                          <HelpOutlineIcon color="primary" />
                                        </Tooltip>
                                      </InputAdornment>
                                    }
                                  >
                                    {listThemes.map((sector) => (
                                      <MenuItem value={sector} key={sector}>
                                        {sector}
                                      </MenuItem>
                                    ))}
                                  </Select>
                                </FormControl>
                              )}
                            />
                            {errors.businessSector && (
                              <span className="text-danger">
                                {errors.businessSector.message}
                              </span>
                            )}
                          </Grid>
                          <Grid item md={6} xs={12}>
                            <Controller
                              name="limitDate"
                              key="limitDate"
                              control={control}
                              render={({ field, onChange }) => (
                                <LocalizationProvider
                                  dateAdapter={AdapterDateFns}
                                  adapterLocale={
                                    localStorage.getItem("i18nextLng") === "en"
                                      ? enGB
                                      : fr
                                  }
                                >
                                  <DemoContainer components={["DatePicker"]}>
                                    <DesktopDatePicker
                                      {...field}
                                      format="dd/MM/yyyy"
                                      disablePast={true}
                                      sx={{
                                        width: "100%",
                                      }}
                                      margin="normal"
                                      label={t("Post's visibility time")}
                                      error={errors.limitDate}
                                      helperText={
                                        errors.limitDate &&
                                        errors.limitDate.message
                                      }
                                      renderInput={(params) => (
                                        <TextField {...params} {...field} />
                                      )}
                                    />
                                  </DemoContainer>
                                </LocalizationProvider>
                              )}
                            />
                            {errors.limitDate && (
                              <span className="text-danger">
                                {errors.limitDate.message}
                              </span>
                            )}
                          </Grid>
                        </Grid>
                        {/* </div>
                      </ul> */}
                        <Button
                          type="submit"
                          className="btn btn-primary d-block w-100 mt-3"
                        >
                          Post
                        </Button>
                      </form>
                    </Modal.Body>
                  </Modal>
                </Card>
              </Col>

              <Col sm={12}>
                <ListPosts></ListPosts>
              </Col>
            </Col>
            {/* <div className="col-sm-12 text-center">
              <img src={loader} alt="loader" style={{ height: "100px" }} />
            </div> */}
          </Row>
        </Container>
        <div style={{ position: "fixed", bottom: 16, right: 16 }}>
          <span>
            <Tooltip title={t("Scroll on top of the page")}>
              <IconButton
                onClick={() => {
                  handleScrollTop();
                }}
              >
                <KeyboardArrowUpIcon />
              </IconButton>
            </Tooltip>
          </span>
        </div>
        <Notification notify={notify} setNotify={setNotify} />
      </div>
    </>
  );
};

export default Index;
