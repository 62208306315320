export const listThemes = [
  "Alimentation et Boissons",
  "Automobile et Transport",
  "Banque et Finance",
  "Bâtiment et Construction",
  "Biens de Consommation",
  "Culture et Société",
  "Éducation et Apprentissage",
  "Électronique et Appareils Électroménagers",
  "Énergie et Services Publics",
  "Équipement Industriel",
  "Habillement et Mode",
  "Immobilier",
  "Informatique et technologies de l'information (TI)",
  "Jouets, Jeux et Divertissement",
  "Produits de Nettoyage et d'Entretien",
  "Santé et Pharmaceutiques",
  "Services de Beauté et Bien-Être",
  "Services Professionnels",
  "Sports et Loisirs",
  "Télécommunications",
  "Tourisme et Voyage",
  "Vente au Détail",
  "Autres",
];

export const retrieveError = (error) => {
  let textError = "";
  for (const [key, value] of Object.entries(error)) {
    if (Array.isArray(value)) {
      value.forEach((errorValue) => {
        textError += `${
          typeof errorValue === "string" ? errorValue : errorValue[0]
        }\n`;
      });
    } else {
      textError += `${typeof value === "string" ? value : value[0]}\n`;
    }
  }
  return textError;
};

export const getInitials = () => {
  let fullname = localStorage.getItem("fullname");
  if (fullname) {
    return `${fullname.split(" ")[0][0]}${fullname.split(" ")[1][0]}`;
  }
};

export const stringToColor = () => {
  let fullName = localStorage.getItem("fullname");
  let avatar = localStorage.getItem("avatar");

  if (fullName) {
    if (avatar && avatar !== "null") {
      return "transparent";
    } else {
      let hash = 0;
      let i;
      for (i = 0; i < fullName.length; i += 1) {
        hash = fullName.charCodeAt(i) + ((hash << 5) - hash);
      }

      let color = "#";

      for (i = 0; i < 3; i += 1) {
        const value = (hash >> (i * 8)) & 0xff;
        color += `00${value.toString(16)}`.slice(-2);
      }

      return color;
    }
  }
};

export const stringToColorBis = (firstName, lastName) => {
  let name = firstName + " " + lastName;
  let hash = 0;
  let i;
  for (i = 0; i < name.length; i += 1) {
    hash = name.charCodeAt(i) + ((hash << 5) - hash);
  }

  let color = "#";

  for (i = 0; i < 3; i += 1) {
    const value = (hash >> (i * 8)) & 0xff;
    color += `00${value.toString(16)}`.slice(-2);
  }

  return color;
};

export const getInitialsBis = (firstName, lastName) => {
  let fullname = firstName + " " + lastName;
  return `${fullname.split(" ")[0][0]}${fullname.split(" ")[1][0]}`;
};
