import React from "react";

import Header from "../../components/partials/dashboard/HeaderStyle/header";

import Footer from "../../components/partials/dashboard/FooterStyle/footer";

import SettingOffCanvas from "../../components/setting/SettingOffCanvas";
import { Outlet } from "react-router-dom";
import { HeaderProvider } from "../../components/partials/dashboard/HeaderStyle/headerContext";

import { JoyrideProvider } from "../../joyrideContext";

const Default = () => {
  return (
    <>
      <HeaderProvider>
        <JoyrideProvider>
          <Header />
          <div className="main-content">
            {/* <div id="content-page" className="content-page"> */}
            {/* <DefaultRouter/> */}
            <Outlet />
            {/* </div> */}
          </div>
          {/* <RightSidebar /> */}
          <Footer />
          {/* <SettingOffCanvas /> */}
        </JoyrideProvider>
      </HeaderProvider>
    </>
  );
};

export default Default;
