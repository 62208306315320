import ClearIcon from "@mui/icons-material/Clear";
import EventIcon from "@mui/icons-material/Event";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { styled } from "@mui/material/styles";
import { useMutation, useQuery } from "@tanstack/react-query";
import React, { useState } from "react";
import ImageGallery from "react-image-gallery";
import ReactPlayer from "react-player/lazy";
import TimeAgo from "react-timeago";
import buildFormatter from "react-timeago/lib/formatters/buildFormatter";
import enStrings from "react-timeago/lib/language-strings/en";
import frenchStrings from "react-timeago/lib/language-strings/fr";
import Notification from "../../../Notification";

import {
  getInitialsBis,
  retrieveError,
  stringToColorBis,
} from "../../../utilities/constants";

import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import {
  Avatar,
  Box,
  CircularProgress,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Tooltip,
  Typography,
} from "@mui/material";
import { format } from "date-fns";
import { Trans, useTranslation } from "react-i18next";
import ConfirmDialog from "../../../ConfirmDialog";
import axiosInstance from "../../../useApi";

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: "var(--bs-primary)",
    color: theme.palette.common.white,
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(odd)": {
    backgroundColor: theme.palette.action.hover,
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const AnswerRow = ({ answer, refetchAnswers }) => {
  const { t, i18n } = useTranslation();
  const formatter = buildFormatter(frenchStrings);
  const formatterBis = buildFormatter(enStrings);
  const [open, setOpen] = React.useState(false);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const [confirmDialog, setConfirmDialog] = useState({
    isOpen: false,
    title: "",
    subTitle: "",
  });

  const deleteMyAnswer = async (idAnswer) => {
    mutationDeleteAnswer.mutate(idAnswer);
  };

  const formatImages = (images) => {
    let formattedImages = [];
    images.forEach((element) => {
      formattedImages.push({
        original: element.image,
        thumbnail: element.image,
        originalHeight: "360",
      });
    });
    return formattedImages;
  };

  const mutationDeleteAnswer = useMutation({
    mutationFn: (idAnswer) => {
      return axiosInstance.delete(
        "https://api-dev.seeks.biz/socialmedia/answers/" + idAnswer
      );
    },
    onError: (error) => {
      setNotify({
        isOpen: true,
        message: retrieveError(error.response.data),
        type: "error",
      });
      console.log("Erreur lors de la suppression de la réponse : ", error);
    },
    onSuccess: (data, variables, context) => {
      setNotify({
        isOpen: true,
        message: t("Answer deleted successfully!"),
        type: "success",
      });
      setConfirmDialog({
        ...confirmDialog,
        isOpen: false,
      });
      refetchAnswers();
    },
  });

  const handleClick = () => {
    setOpen(!open);
  };

  return (
    <>
      <StyledTableRow key={answer.text}>
        <Tooltip title={t("See attachment(s)")}>
          <StyledTableCell>
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={handleClick}
            >
              {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </IconButton>
          </StyledTableCell>
        </Tooltip>
        {/* <StyledTableCell component="th" scope="row">
          <div style={{ display: "flex", alignItems: "center" }}>
            {answer.author?.avatar !== null ? (
              <div style={{ marginRight: "8px" }}>
                <Avatar src={answer.author?.avatar}></Avatar>
              </div>
            ) : null}
            <div>
              {answer.author?.first_name} {answer.author?.last_name} (
              {answer.author?.company_name})
            </div>
          </div>
        </StyledTableCell> */}
        <StyledTableCell>{answer.text}</StyledTableCell>
        <StyledTableCell align="right">{answer.link}</StyledTableCell>
        <StyledTableCell align="right">
          {answer.quotation || answer.price == null ? (
            <Trans>Price on quote</Trans>
          ) : parseFloat(answer.price?.split(".")[1]) % 1 !== 0 ? (
            parseFloat(answer.price) + "€"
          ) : (
            parseFloat(answer.price) + "€"
          )}
        </StyledTableCell>
        <StyledTableCell align="right">
          <TimeAgo
            date={answer.created_at}
            formatter={
              localStorage.getItem("i18next") === "fr"
                ? formatterBis
                : formatter
            }
          />
        </StyledTableCell>
        <StyledTableCell>
          <IconButton
            onClick={() => {
              setConfirmDialog({
                isOpen: true,
                title: "Are you sure you want to delete this answer?",
                subTitle: "You can't undo this operation",
                onConfirm: () => {
                  deleteMyAnswer(answer.id);
                },
              });
            }}
          >
            <ClearIcon />
          </IconButton>
        </StyledTableCell>
      </StyledTableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={10}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            {answer.images &&
            answer.images.length == 0 &&
            answer.attached_files &&
            answer.attached_files.length == 0 ? (
              <div style={{ justifyContent: "center", display: "flex" }}>
                <Trans>No image nor attached file</Trans>
              </div>
            ) : (
              <Grid container>
                <Grid item xs={12}>
                  {answer.images && answer.images.length > 0 ? (
                    <ImageGallery
                      items={formatImages(answer.images)}
                      showPlayButton={false}
                      thumbnailPosition="right"
                      useBrowserFullscreen={false}
                    />
                  ) : null}
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {answer.attached_files &&
                    answer.attached_files.length > 0 &&
                    answer.attached_files.find(
                      (elt) => !elt.attached?.endsWith(".pdf")
                    )
                      ? answer.attached_files
                          .filter((elt) => !elt.attached?.endsWith(".pdf"))
                          .map((video) => (
                            <Grid item xl={4} md={6} xs={12}>
                              <ReactPlayer
                                url={video.attached}
                                controls={true}
                                width="100%"
                                height="100%"
                              />
                            </Grid>
                          ))
                      : null}
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  <Grid container spacing={2}>
                    {answer.attached_files &&
                    answer.attached_files.length > 0 &&
                    answer.attached_files.find((elt) =>
                      elt.attached?.endsWith(".pdf")
                    )
                      ? answer.attached_files
                          .filter((elt) => elt.attached?.endsWith(".pdf"))
                          .map((file) => (
                            <Grid item key={file.id}>
                              <a
                                href={file.attached}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Box
                                  elevation={3}
                                  sx={{
                                    position: "relative",
                                  }}
                                >
                                  <PictureAsPdfIcon
                                    color="error"
                                    sx={{ fontSize: 64 }}
                                  />
                                  <Tooltip
                                    title={
                                      file.attached.split(
                                        "/media/answers/attached/"
                                      )[1]
                                    }
                                  >
                                    <Typography
                                      sx={{
                                        maxWidth: 100,
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {
                                        file.attached.split(
                                          "/media/answers/attached/"
                                        )[1]
                                      }
                                    </Typography>
                                  </Tooltip>
                                </Box>
                              </a>
                            </Grid>
                          ))
                      : null}
                  </Grid>
                </Grid>
              </Grid>
            )}
          </Collapse>
        </TableCell>
      </TableRow>
      <ConfirmDialog
        confirmDialog={confirmDialog}
        setConfirmDialog={setConfirmDialog}
      />

      <Notification notify={notify} setNotify={setNotify} />
    </>
  );
};

const ListMyAnswers = ({ setAnswersCount }) => {
  const { t, i18n } = useTranslation();

  const formatter = buildFormatter(frenchStrings);
  const formatterBis = buildFormatter(enStrings);

  const [notify, setNotify] = useState({
    isOpen: false,
    message: "",
    type: "",
  });

  const fetchMyAnswers = async () => {
    const { data } = await axiosInstance.get(
      "/socialmedia/myreplies?ordering=-answers__id"
    );
    setAnswersCount(data.count);
    return data;
  };

  const {
    data: myAnswers,
    isLoadingPosts,
    refetch,
  } = useQuery(["myAnswers"], () => fetchMyAnswers(), {
    retry: false,
  });

  const formatImages = (images) => {
    let formattedImages = [];
    images.forEach((element) => {
      formattedImages.push({
        original: element.image,
        thumbnail: element.image,
        originalHeight: "360",
      });
    });
    return formattedImages;
  };

  return (
    <>
      {!myAnswers || myAnswers.length <= 0 ? (
        <div className="col-sm-12 text-center">
          <CircularProgress />
        </div>
      ) : myAnswers.results && myAnswers.results.length <= 0 ? (
        <div style={{ textAlign: "center", fontSize: "18px" }}>
          <Trans>No answer found</Trans>
        </div>
      ) : (
        myAnswers?.results.map((post, index) => (
          <div key={post.id}>
            <div className="card card-block card-stretch card-height">
              <div className="card-body">
                <div className="user-post-data">
                  <div className="d-flex justify-content-between">
                    <div className="me-3">
                      <Avatar
                        src={post.author.avatar}
                        sx={{
                          bgcolor:
                            post.author.avatar === null ||
                            post.author.avatar === ""
                              ? stringToColorBis(
                                  post.author.first_name,
                                  post.author.last_name
                                )
                              : "transparent",
                        }}
                      >
                        {post.author &&
                        post.author.avatar &&
                        post.author.avatar !== "null"
                          ? post.author.avatar
                          : getInitialsBis()}
                      </Avatar>
                    </div>
                    <div className="w-100">
                      <div className="d-flex  justify-content-between">
                        <div>
                          <h5 className="mb-0 d-inline-block">
                            {post.author?.first_name} {post.author?.last_name}
                          </h5>
                          <span className="mb-0 ps-1 d-inline-block">
                            <Trans>Added a new Post in sector</Trans>
                            &nbsp;{post.business_sector}
                          </span>
                          <p className="mb-0 text-primary">
                            <TimeAgo
                              date={post.created_at}
                              formatter={
                                localStorage.getItem("i18next") === "fr"
                                  ? formatterBis
                                  : formatter
                              }
                            />
                          </p>
                        </div>
                        <div className="card-post-toolbar">
                          <div style={{ textAlign: "end" }}>
                            <LocationOnIcon />{" "}
                            {post.location_type === "local" ? (
                              post.location +
                              " (+" +
                              post.location_radius +
                              " km)"
                            ) : post.location_type === "régional" ? (
                              post.location
                            ) : (
                              <Trans>National</Trans>
                            )}
                          </div>
                          <div style={{ textAlign: "end" }}>
                            <EventIcon />{" "}
                            {format(new Date(post.valid_until), "dd-MM-yyyy")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-3">
                  <p style={{ whiteSpace: "pre-line" }}>{post.content}</p>
                </div>
                <div className="user-post mt-3">
                  {post.images && post.images.length > 0 ? (
                    <ImageGallery
                      loading={"lazy"}
                      items={formatImages(post.images)}
                      showPlayButton={false}
                      thumbnailPosition="right"
                      useBrowserFullscreen={false}
                    />
                  ) : null}
                </div>
                <div className="user-post mt-3">
                  <Grid container spacing={2}>
                    {post.attached_files &&
                    post.attached_files.length > 0 &&
                    post.attached_files.find(
                      (elt) => !elt.attached?.endsWith(".pdf")
                    )
                      ? post.attached_files
                          .filter((elt) => !elt.attached?.endsWith(".pdf"))
                          .map((video) => (
                            <Grid item xl={4} md={6} xs={12}>
                              <ReactPlayer
                                url={video.attached}
                                controls={true}
                                width="100%"
                                height="100%"
                              />
                            </Grid>
                          ))
                      : null}
                  </Grid>
                </div>
                <div className="user-post mt-3">
                  <Grid container spacing={2}>
                    {post.attached_files &&
                    post.attached_files.length > 0 &&
                    post.attached_files.find((elt) =>
                      elt.attached?.endsWith(".pdf")
                    )
                      ? post.attached_files
                          .filter((elt) => elt.attached?.endsWith(".pdf"))
                          .map((file) => (
                            <Grid item key={file.id}>
                              <a
                                href={file.attached}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <Box
                                  elevation={3}
                                  sx={{
                                    position: "relative",
                                  }}
                                >
                                  <PictureAsPdfIcon
                                    color="error"
                                    sx={{ fontSize: 64 }}
                                  />
                                  <Tooltip
                                    title={
                                      file.attached.split(
                                        "/media/answers/attached/"
                                      )[1]
                                    }
                                  >
                                    <Typography
                                      sx={{
                                        maxWidth: 100,
                                        overflow: "hidden",
                                        textOverflow: "ellipsis",
                                        whiteSpace: "nowrap",
                                        fontSize: "12px",
                                      }}
                                    >
                                      {
                                        file.attached.split(
                                          "/media/answers/attached/"
                                        )[1]
                                      }
                                    </Typography>
                                  </Tooltip>
                                </Box>
                              </a>
                            </Grid>
                          ))
                      : null}
                  </Grid>
                </div>
                <Grid container spacing={2} style={{ marginTop: "2px" }}>
                  <Grid item xs={12}>
                    {post.answers && post.answers.length <= 0 ? (
                      <div
                        style={{
                          textAlign: "center",
                          fontSize: "18px",
                        }}
                      >
                        <Trans>No answer yet</Trans>
                      </div>
                    ) : (
                      <TableContainer component={Paper}>
                        <Table
                          sx={{ minWidth: 700 }}
                          aria-label="customized table"
                        >
                          <TableHead>
                            <TableRow>
                              <StyledTableCell />
                              <StyledTableCell>
                                <Trans>Content</Trans>
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                <Trans>Link</Trans>
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                <Trans>Price</Trans>
                              </StyledTableCell>
                              <StyledTableCell align="right">
                                <Trans>Date and time</Trans>
                              </StyledTableCell>
                              <StyledTableCell></StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {post.answers &&
                              post.answers.map((answer) => (
                                <AnswerRow
                                  key={answer.text}
                                  answer={answer}
                                  refetchAnswers={refetch}
                                />
                              ))}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    )}
                  </Grid>
                </Grid>
              </div>
            </div>
          </div>
        ))
      )}
    </>
  );
};

export default ListMyAnswers;
