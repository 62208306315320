import { useEffect, useState } from "react";
import { Fab, Divider, Grid } from "@mui/material";
import { Image } from "react-bootstrap";
import ChoiceIcon from "../../../assets/images/icon/choice.png";
import BalanceIcon from "../../../assets/images/icon/balance.png";
import ExpansesIcon from "../../../assets/images/icon/expanses.png";
import HandshakeIcon from "../../../assets/images/icon/handshake.png";
import LocationIcon from "../../../assets/images/icon/location.png";
import PartnersIcon from "../../../assets/images/icon/partners.png";
import TimeIcon from "../../../assets/images/icon/time.png";
import EuroIcon from "../../../assets/images/icon/euro.png";
import { TypeAnimation } from "react-type-animation";
import SyncIcon from "@mui/icons-material/Sync";
import SyncAltIcon from "@mui/icons-material/SyncAlt";
import logo2 from "../../../assets/images/presentation-page/Image4.png";

const PresentationPage = () => {
  const [isMobileView, setIsMobileView] = useState(
    window.innerWidth <= 960 ? true : false
  );

  useEffect(() => {
    const handleResize = () => {
      setIsMobileView(window.innerWidth <= 960);
    };

    window.addEventListener("resize", handleResize);

    // Clean up
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="presentation-page" style={{}}>
      {/* <Container style={{ maxWidth: "100vw", background: "#0B163E" }}>
        <br />
        <br />
        <Row className="mb-5">
          <Col style={{ justifyContent: "center", display: "flex" }}>
            <Grid
              container
              spacing={2}
              sx={{
                maxWidth: 369,
              }}
            >
              <Grid item xs={12}>
                <Image src={logo} className="img-fluid" alt="logo" />
              </Grid>
              <Grid item xs={12}>
                <Card
                  style={{
                    background:
                      "linear-gradient(90deg, rgb(24, 32, 57),  rgb(46, 96, 133), rgb(24, 32, 57))",
                    marginTop: "-18px",
                  }}
                >
                  <CardContent>
                    <h3 style={{ textAlign: "center" }}>
                      Bienvenue chez SEEKS!
                    </h3>
                  </CardContent>
                </Card>
                <br />
                <br />

                <br />
                <br />
              </Grid>
            </Grid>
          </Col>
        </Row>
      </Container> */}
      {/* Section 1 */}

      <Grid
        container
        spacing={2}
        style={{
          marginTop: "-32px",
          background: "#0B163E",
          marginBottom: "32px",
          minHeight: "100%",
          overflowX: "hidden",
          display: "flex",
          alignItems: "center",
        }}
      >
        <Grid item xs={6}>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginLeft: "32px",
            }}
          >
            <h1
              style={{
                display: "flex",
                justifyContent: "left",
                textAlign: "left",
                textAlignLast: "left",
                width: "58vw",
                fontStyle: "normal",
                fontWeight: "400",
                fontSize: "64px",
                lineHeight: "110%",
                // paddingRight: "13vw",
              }}
            >
              <div
                style={{
                  color: "#f7f7f8",
                  marginTop: isMobileView ? "50px" : null,
                  marginBottom: isMobileView ? "50px" : null,
                }}
              >
                <span
                  style={{
                    WebkitTextStroke: "1px rgb(41, 0, 247)",
                    color: "white",
                    textShadow:
                      "3px 3px 0 rgb(41, 0, 247), -1px -1px 0 rgb(41, 0, 247), 1px -1px 0 rgb(41, 0, 247), -1px 1px 0 rgb(41, 0, 247), 1px 1px 0 rgb(41, 0, 247)",
                  }}
                >
                  SEEKS
                </span>
                , le premier réseau social{" "}
                <TypeAnimation
                  sequence={["BtoB", 2500, "français", 2500, "gratuit", 2500]}
                  wrapper="span"
                  speed={50}
                  style={{ backgroundColor: "#2900f7" }}
                  repeat={Infinity}
                />
              </div>
            </h1>
          </div>
          {/* <h3
            style={{
              display: "flex",
              justifyContent: "left",
              textAlign: "left",
              textAlignLast: "left",
              width: "58vw",
              fontStyle: "normal",
              lineHeight: "110%",
              marginLeft: "5vw",
              paddingRight: "15vw",
              color: "#f7f7f8",
              marginTop: "42px",
            }}
          >
            SEEKS est un réseau social révolutionnaire conçu pour faciliter la
            mise en relation entre acheteurs et vendeurs, mais avec une approche
            totalement différente.
          </h3> */}
        </Grid>
        {!isMobileView ? (
          <Grid item xs={6}>
            <Image
              src={logo2}
              alt="logo"
              style={{
                // width: "auto", // Ajuste la largeur de l'image en conservant sa proportion d'origine
                // height: "100vh", // Utilisation de 100vh pour que l'image prenne toute la hauteur de l'écran
                // objectFit: "contain", // Pour s'assurer que l'image est entièrement visible sans déborder
                // maxHeight: "100%", // Pour éviter que l'image ne dépasse en hauteur
                width: "100%", // Assurez-vous que l'image occupe toute la largeur
                height: "auto", // Ajuste la hauteur en conséquence
              }}
            />
          </Grid>
        ) : null}
        {/* <Grid item xs={6} sx={{ marginBottom: "-16px" }}>
          
        </Grid> */}
      </Grid>

      <Grid
        container
        style={{
          maxWidth: "100vw",
          background: "#f7f7f8",
          marginTop: "-32px",
          paddingBottom: "42px",
        }}
      >
        <Grid item xs={12}>
          <h2
            className="text-center"
            style={{
              color: "#0B163E",
              fontWeight: "bold",
              marginTop: "32px",
              marginBottom: "32px",
            }}
          >
            Comment ça marche ?
          </h2>
          <h3
            className="text-center"
            style={{
              color: "#2900f7",
              fontWeight: "bold",
              marginTop: "32px",
              marginBottom: "32px",
            }}
          >
            Inscrivez-vous gratuitement et choisissez 3 mots.
          </h3>
        </Grid>
        <Grid
          item
          md={5}
          xs={12}
          style={{
            paddingLeft: "8%",
            paddingRight: isMobileView ? "8%" : "0%",
            color: "#272727",
            paddingBottom: "24px",
          }}
        >
          <div
            style={{
              justifyContent: "center",
              display: "flex",
            }}
          >
            <Fab
              variant="extended"
              sx={{
                color: "#f7f7f8",
                background:
                  // "linear-gradient(90deg, rgb(24, 32, 57) , rgb(23, 70, 103) 45%, rgb(24, 32, 57 ))",
                  "#0B163E",
                fontWeight: "bold",
                "&:hover": {
                  backgroundColor: "#0B163E",
                  cursor: "pointer",
                },
              }}
            >
              Acheteur
            </Fab>
          </div>
          <br />
          <div>
            <h4
              style={{
                color: "#272727",
              }}
            >
              Si ce que vous cherchez implique une <b>localisation</b>, une{" "}
              <b>quantité</b>, ou encore une <b>spécificité</b> que vous
              n'arrivez pas à trouver, vous êtes au bon endroit !
            </h4>
          </div>
          <br />
          <div>
            <h4
              style={{
                color: "#272727",
              }}
            >
              Pour trouver votre futur <b>partenaire commercial</b>, il vous
              suffit de faire un <b>post</b> le plus détaillé possible et la
              communauté <b style={{ color: "#2900f7" }}>SEEKS</b> tentera d'y
              répondre.
            </h4>
          </div>
          {/* <br />
          <div>
            <h4
              style={{
                color: "#272727",
              }}
            >
              Une fois sur votre mur principal,{" "}
              <b>faites un post et attendez</b> que la communauté{" "}
              <b style={{ color: "#2900f7" }}>SEEKS</b> vous réponde.
            </h4>
          </div> */}
          <br />
          <div>
            <h4
              style={{
                color: "#272727",
              }}
            >
              Quand l'un de nos membre vous fera une proposition{" "}
              <b>
                vous serez informé par mail et vous pourrez visualiser la
                réponse
              </b>{" "}
              sur votre mur personnel.
            </h4>
          </div>
          <br />
          <div>
            <h4
              style={{
                color: "#272727",
              }}
            >
              Il ne reste plus qu'à{" "}
              <b>prendre contact avec votre fournisseur</b> pour définir les
              modalités de votre transaction.
            </h4>
          </div>
        </Grid>
        <Grid
          item
          md={2}
          xs={12}
          style={{
            justifyContent: "center",
            display: "flex",
          }}
        >
          {isMobileView ? (
            <SyncIcon style={{ fontSize: "3.5rem", color: "#0B163E" }} />
          ) : (
            <SyncAltIcon
              style={{
                fontSize: "3.5rem",
                color: "#0B163E",
                marginTop: "226px",
              }}
            />
          )}
        </Grid>
        <Grid
          item
          md={5}
          xs={12}
          style={{
            paddingRight: "8%",
            paddingLeft: isMobileView ? "8%" : "0%",
            color: "#272727",
          }}
        >
          <div
            style={{
              marginTop: isMobileView ? "24px" : null,
            }}
          >
            <div
              style={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              <Fab
                variant="extended"
                sx={{
                  color: "#f7f7f8",
                  background:
                    // "linear-gradient(90deg, rgb(24, 32, 57) , rgb(23, 70, 103) 45%, rgb(24, 32, 57 ))",
                    "#0B163E",
                  fontWeight: "bold",
                  cursor: "pointer",
                  "&:hover": {
                    backgroundColor: "#0B163E",
                  },
                }}
              >
                Vendeur
              </Fab>
            </div>
            <br />
            <div>
              <h4
                style={{
                  color: "#272727",
                }}
              >
                Trouver vos futurs clients n'a jamais été aussi <b>facile</b>.
              </h4>
            </div>
            <br />
            <div>
              <h4
                style={{
                  color: "#272727",
                }}
              >
                Sur <b style={{ color: "#2900f7" }}>SEEKS</b> la communauté post
                une recherche de produit ou de service et si vous êtes en
                capacité d'y répondre vous pourrez faire votre{" "}
                <b>proposition commerciale</b>.
              </h4>
            </div>
            <br />
            {/* <div>
              <h4
                style={{
                  color: "#272727",
                }}
              >
                Ces 3 mots définiront les posts auxquelles{" "}
                <b>vous pourrez répondre.</b>
              </h4>
            </div> */}
            <br />
            <div>
              <h4
                style={{
                  color: "#272727",
                }}
              >
                Si l'un des membres de <b style={{ color: "#2900f7" }}>SEEKS</b>{" "}
                poste une demande contenant l'un de vos 3 mots. Vous serez{" "}
                <b>
                  informé par mail que vous avez un prospect qui recherche
                  potentiellement votre solution.
                </b>
              </h4>
            </div>
            <br />
            <div>
              <h4
                style={{
                  color: "#272727",
                }}
              >
                Il suffit de <b>proposer vos services</b> et d'attendre le
                retour potentiel de votre prospect.
              </h4>
            </div>
          </div>
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          maxWidth: "100vw",
          background: "#0B163E",
        }}
      >
        <Grid
          item
          xs={12}
          style={{
            marginTop: "32px",
            marginBottom: "50px",
            color: "",
          }}
        >
          <h2 className="text-center" style={{ color: "#f7f7f8" }}>
            <b>Pourquoi choisir</b> <b style={{ color: "#2900f7" }}>SEEKS?</b>
          </h2>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            marginBottom: "32px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <h3 className="text-center" style={{ color: "#f7f7f8" }}>
            <b>Acheteur</b>
            <Divider
              style={{
                background: "#2900f7",
                borderBottomWidth: 2,
                opacity: 1,
                width: "142px",
              }}
            />
          </h3>
        </Grid>
        <Grid
          item
          md={3}
          xs={12}
          style={{
            marginBottom: "32px",
          }}
        >
          <div className="padding-24">
            <div
              style={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              <img
                src={PartnersIcon}
                className="img-fluid"
                alt="logo"
                style={{ width: "76px", height: "auto" }}
              />
            </div>
            <br />
            <div style={{ color: "#f7f7f8", textAlign: "center" }}>
              <b>DÉNICHER DE NOUVEAUX FOURNISSEURS DE QUALITÉ</b>
            </div>
          </div>
        </Grid>
        <Grid
          item
          md={3}
          xs={12}
          style={{
            justifyContent: "center",
            display: "flex",
            marginBottom: "32px",
          }}
        >
          <div className="padding-24">
            <div
              style={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              <img
                src={TimeIcon}
                className="img-fluid"
                alt="logo"
                style={{ width: "76px", height: "auto" }}
              />
            </div>
            <br />
            <div style={{ color: "#f7f7f8", textAlign: "center" }}>
              <b>OPTIMISER VOTRE TEMPS AU MAXIMUM</b>
            </div>
          </div>
        </Grid>
        <Grid
          item
          md={3}
          xs={12}
          style={{
            justifyContent: "center",
            display: "flex",
            marginBottom: "32px",
          }}
        >
          <div className="padding-24">
            <div
              style={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              <img
                src={LocationIcon}
                className="img-fluid"
                alt="logo"
                style={{ width: "76px", height: "auto" }}
              />
            </div>
            <br />
            <div style={{ color: "#f7f7f8", textAlign: "center" }}>
              <b>RECHERCHER À TOUTE ÉCHELLE GÉOGRAPHIQUE</b>
            </div>
          </div>
        </Grid>

        <Grid
          item
          md={3}
          xs={12}
          style={{
            justifyContent: "center",
            display: "flex",
            marginBottom: "32px",
          }}
        >
          <div className="padding-24">
            <div
              style={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              <img
                src={ChoiceIcon}
                className="img-fluid"
                alt="logo"
                style={{ width: "76px", height: "auto" }}
              />
            </div>
            <br />
            <div style={{ color: "#f7f7f8", textAlign: "center" }}>
              <b>SIMPLIFIER LA COMPARAISON DES PROPOSITIONS</b>
            </div>
          </div>
        </Grid>

        <Grid
          item
          xs={12}
          style={{
            marginBottom: "32px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <h3 className="text-center" style={{ color: "#f7f7f8" }}>
            <b>Vendeur</b>
            <Divider
              style={{
                background: "#2900f7",
                borderBottomWidth: 2,
                opacity: 1,
                width: "142px",
              }}
            />
          </h3>
        </Grid>

        <Grid
          item
          md={3}
          xs={12}
          style={{
            marginBottom: "32px",
          }}
        >
          <div className="padding-24">
            <div
              style={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              <img
                src={ExpansesIcon}
                className="img-fluid"
                alt="logo"
                style={{ width: "76px", height: "auto" }}
              />
            </div>
            <br />
            <div style={{ color: "#f7f7f8", textAlign: "center" }}>
              <b>METTRE FIN AUX DÉPENSES EXCESSIVES EN MARKETING</b>
            </div>
          </div>
        </Grid>
        <Grid
          item
          md={3}
          xs={12}
          style={{
            justifyContent: "center",
            display: "flex",
            marginBottom: "32px",
          }}
        >
          <div className="padding-24">
            <div
              style={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              <img
                src={EuroIcon}
                className="img-fluid"
                alt="logo"
                style={{ width: "76px", height: "auto" }}
              />
            </div>
            <br />
            <div style={{ color: "#f7f7f8", textAlign: "center" }}>
              <b>GÉNÉRATEUR DE LEADS GRATUITS</b>
            </div>
          </div>
        </Grid>
        <Grid
          item
          md={3}
          xs={12}
          style={{
            justifyContent: "center",
            display: "flex",
            marginBottom: "32px",
          }}
        >
          <div className="padding-24">
            <div
              style={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              <img
                src={HandshakeIcon}
                className="img-fluid"
                alt="logo"
                style={{ width: "76px", height: "auto" }}
              />
            </div>
            <br />
            <div style={{ color: "#f7f7f8", textAlign: "center" }}>
              <b>DÉCOUVRIR DE NOUVEAUX PARTENAIRES</b>
            </div>
          </div>
        </Grid>

        <Grid
          item
          md={3}
          xs={12}
          style={{
            justifyContent: "center",
            display: "flex",
            marginBottom: "32px",
          }}
        >
          <div className="padding-24">
            <div
              style={{
                justifyContent: "center",
                display: "flex",
              }}
            >
              <img
                src={BalanceIcon}
                className="img-fluid"
                alt="logo"
                style={{ width: "76px", height: "auto" }}
              />
            </div>
            <br />
            <div style={{ color: "#f7f7f8", textAlign: "center" }}>
              <b>
                OFFRIR UNE OPPORTUNITÉ ÉQUITABLE DE PRÉSENTER VOTRE SOLUTION
              </b>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default PresentationPage;
