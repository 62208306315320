import { Button, Col, Container, Image, Row } from "react-bootstrap";

import { Trans } from "react-i18next";

import "swiper/swiper-bundle.min.css";

import axios from "axios";
import mail from "../../../assets/images/login/mail.png";

import { useQuery } from "@tanstack/react-query";
import Swipper from "./swipper";

const ConfirmMail = () => {
  const fetchTokens = async (token, user) => {
    const { data } = await axios.get(
      `https://api-dev.seeks.biz/users/validate-activation-token?token=${token}&user=${user}`
    );
    console.log(data);
    return data;
  };

  const { data, error, isLoading } = useQuery(
    ["tokens"],
    () =>
      fetchTokens(
        window.location.search.split("?token=")[1].split("&user=")[0],
        window.location.search.split("&user=")[1]
      ),
    {
      retry: false,
      onSuccess: (data) => {
        localStorage.clear();
        localStorage.setItem("access_token", data.access);
        localStorage.setItem("refresh_token", data.refresh);
        localStorage.setItem(
          "fullname",
          data.first_name + " " + data.last_name
        );
        localStorage.setItem("email", data.email);
        localStorage.setItem("words", JSON.stringify(data.words));
        localStorage.setItem("address", data.address);
        localStorage.setItem("longitude", data.longitude);
        localStorage.setItem("latitude", data.latitude);
      },
    }
  );

  return (
    <>
      <section className="sign-in-page">
        <div id="container-inside">
          <div id="circle-small"></div>
          <div id="circle-medium"></div>
          <div id="circle-large"></div>
          <div id="circle-xlarge"></div>
          <div id="circle-xxlarge"></div>
        </div>
        <Container className="p-0">
          <Row className="no-gutters">
            <Col md="6" className="text-center pt-5 order-md-1 order-2">
              <Swipper />
            </Col>
            <Col
              md="6"
              className="bg-white pt-5 pt-5 pb-lg-0 pb-5 order-md-2 order-1"
            >
              <div className="sign-in-from">
                <Image src={mail} width="80" alt="" />

                <h1 className="mt-3 mb-0">
                  {error ? <Trans>Error !</Trans> : <Trans>Success !</Trans>}
                </h1>
                <p>
                  {error ? (
                    <div>
                      Error:{" "}
                      {error.response?.data?.message
                        ? error.response?.data?.message
                        : error.message}
                    </div>
                  ) : (
                    <Trans>
                      Your account has been successfully created and you are now
                      connected. Just click on the button below to be redirected
                      to the home page.
                    </Trans>
                  )}
                </p>
                <div className="d-inline-block w-100">
                  <Button
                    type="button"
                    onClick={() => (window.location.href = "/")}
                    variant="primary"
                    className="mt-3"
                  >
                    <span className="d-flex align-items-center">
                      <i className="material-symbols-outlined md-18 me-1">
                        home
                      </i>
                      <Trans>Go to Home page</Trans>
                    </span>
                  </Button>
                </div>
              </div>
            </Col>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default ConfirmMail;
