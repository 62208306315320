// HeaderContext.js
import React, { createContext, useState } from "react";

const HeaderContext = createContext();

const HeaderProvider = ({ children }) => {
  const [headerValue, setHeaderValue] = useState("");
  const [avatarValue, setAvatarValue] = useState(
    localStorage.getItem("avatar")
  );
  const [fullnameValue, setFullnameValue] = useState(
    localStorage.getItem("fullname")
  );

  const updateFullnameValue = (value) => {
    setFullnameValue(value);
  };

  const updateAvatarValue = (value) => {
    setAvatarValue(value);
  };

  const updateHeaderValue = (value) => {
    setHeaderValue(value);
  };

  return (
    <HeaderContext.Provider
      value={{
        headerValue,
        updateHeaderValue,
        avatarValue,
        updateAvatarValue,
        fullnameValue,
        updateFullnameValue,
      }}
    >
      {children}
    </HeaderContext.Provider>
  );
};

export { HeaderContext, HeaderProvider };
